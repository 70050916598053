export const WORDS = [
 'agabo',
 'silas',
 'maria',
 'salmo',
 'palha',
 'armas',
 'trigo',
 'pedra',
 'furia',
 'corte',
 'cofre',
 'aguia',
 'brasa',
 'censo',
 'prato',
 'andre',
 'naama',
 'pesos',
 'trave',
 'grito',
 'assaz',
 'sinal',
 'eglom',
 'alamo',
 'sulco',
 'agata',
 'tadeu',
 'jetro',
 'jaula',
 'poder',
 'covil',
 'pasmo',
 'diabo',
 'diana',
 'gaita',
 'ruido',
 'carga',
 'hinos',
 'lucio',
 'manre',
 'bicho',
 'judas',
 'pulga',
 'orion',
 'cobra',
 'filho',
 'cetro',
 'reino',
 'siria',
 'farto',
 'tocha',
 'loide',
 'coroa',
 'purim',
 'siloe',
 'noite',
 'posse',
 'abrao',
 'impio',
 'babel',
 'cabul',
 'monte',
 'cervo',
 'adufe',
 'vasti',
 'lidia',
 'queda',
 'geazi',
 'moeda',
 'palmo',
 'farao',
 'soldo',
 'ruina',
 'cabra',
 'beijo',
 'cesto',
 'altar',
 'labao',
 'turno',
 'sonho',
 'atrio',
 'mirra',
 'arade',
 'bando',
 'curso',
 'acabe',
 'jesus',
 'foice',
 'comum',
 'ester',
 'sinai',
 'vento',
 'irmao',
 'manco',
 'efata',
 'efode',
 'verga',
 'chuva',
 'furor',
 'linha',
 'leite',
 'viril',
 'cesar',
 'gogue',
 'exito',
 'darda',
 'mosca',
 'gibea',
 'timao',
 'felix',
 'sarna',
 'sinar',
 'amigo',
 'corvo',
 'grade',
 'tinta',
 'cades',
 'coito',
 'abdom',
 'rumor',
 'julio',
 'asafe',
 'mente',
 'milca',
 'seara',
 'harpa',
 'sarom',
 'ponto',
 'folga',
 'vagar',
 'nudez',
 'efrom',
 'lebre',
 'grilo',
 'simao',
 'ruivo',
 'nardo',
 'tribo',
 'termo',
 'julia',
 'bispo',
 'campo',
 'fardo',
 'dario',
 'nereu',
 'fruto',
 'pleno',
 'faixa',
 'pomba',
 'lagar',
 'mical',
 'tumim',
 'apolo',
 'adiel',
 'temor',
 'casta',
 'elias',
 'livro',
 'galio',
 'egito',
 'ebano',
 'ferir',
 'horta',
 'verao',
 'medos',
 'grego',
 'zilpa',
 'limpo',
 'dobre',
 'afeto',
 'pilar',
 'clara',
 'asera',
 'sogro',
 'exodo',
 'ruben',
 'mosto',
 'pudor',
 'arabe',
 'dobro',
 'licor',
 'crivo',
 'pompa',
 'tropa',
 'trono',
 'eliel',
 'seiva',
 'varoa',
 'roubo',
 'prata',
 'preso',
 'uzias',
 'media',
 'tinto',
 'tinha',
 'louco',
 'nuvem',
 'emaus',
 'india',
 'fonte',
 'viuva',
 'corda',
 'falta',
 'navio',
 'raabe',
 'sabio',
 'lesma',
 'malco',
 'raiva',
 'cedro',
 'moabe',
 'peste',
 'ungir',
 'prego',
 'creta',
 'noemi',
 'festa',
 'salva',
 'asael',
 'tenaz',
 'prumo',
 'raama',
 'ficol',
 'junco',
 'sagaz',
 'alfeu',
 'mundo',
 'pisom',
 'chave',
 'marta',
 'fluxo',
 'leste',
 'madre',
 'pavor',
 'pedro',
 'ceifa',
 'lucas',
 'jaspe',
 'noiva',
 'dagom',
 'ecrom',
 'manto',
 'orfao',
 'cruel',
 'velho',
 'redil',
 'barro',
 'culto',
 'fenda',
 'passa',
 'besta',
 'selva',
 'abner',
 'penha',
 'jubal',
 'carro',
 'mitra',
 'cozer',
 'genro',
 'latim',
 'trato',
 'escol',
 'santo',
 'trapo',
 'rocha',
 'vinho',
 'jebus',
 'zicri',
 'bacia',
 'habil',
 'judeu',
 'porta',
 'pobre',
 'ponta',
 'dardo',
 'veloz',
 'ariel',
 'tarso',
 'magos',
 'libia',
 'merce',
 'homem',
 'relva',
 'joabe',
 'betel',
 'anrao',
 'feito',
 'freio',
 'doido',
 'arnom',
 'josue',
 'heber',
 'febre',
 'nobre',
 'jonas',
 'siclo',
 'sarai',
 'ardil',
 'malha',
 'nevoa',
 'morto',
 'forca',
 'presa',
 'edito',
 'feita',
 'endro',
 'helem',
 'tedio',
 'gerar',
 'prole',
 'tufao',
 'feixe',
 'justo',
 'favor',
 'manso',
 'servo',
 'morte',
 'cauda',
 'malta',
 'funda',
 'senir',
 'magoa',
 'senso',
 'aroma',
 'orgao',
 'tempo',
 'carne',
 'coral',
 'paulo',
 'peixe',
 'leito',
 'casco',
 'misto',
 'veado',
 'ardor',
 'tamar',
 'selai',
 'porco',
 'debil',
 'arido',
 'saulo',
 'semen',
 'sabor',
 'facho',
 'midia',
 'vinha',
 'ciume',
 'visao',
 'cinta',
 'ferro',
 'touro',
 'amnom',
 'verbo',
 'roupa',
 'baasa',
 'resto',
 'simei',
 'arado',
 'rigor',
 'varao',
 'aloes',
 'chaga',
 'minas',
 'tenda',
 'vigia',
 'jairo',
 'cinto',
 'vulto',
 'molho',
 'vigor',
 'seixo',
 'calma',
 'jejum',
 'torre',
 'serra',
 'lirio',
 'idolo',
 'vidro',
 'belem',
 'miria',
 'praga',
 'patio',
 'efeso',
 'milha',
 'salem',
 'borra',
 'jesse',
 'abibe',
 'lugar',
 'barba',
 'seita',
 'linho',
 'canaa',
 'juizo',
 'tenro',
 'regra',
 'tiago',
 'calva',
 'bodas',
 'bolsa',
 'culpa',
 'terra',
 'elisa',
 'urias',
 'amasa',
 'torpe',
 'ninho',
 'forno',
 'honra',
 'pasta',
 'geada',
 'carta',
 'coate',
 'anzol',
 'omega',
 'poupa',
 'tigre',
 'lepra',
 'verme',
]
