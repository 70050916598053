import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Como jogar" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-white dark:text-gray-300">
        Adivinhe a palavra em até 6 tentativas.	Após cada tentativa, as cores das letras vão indicar o quão perto você está da palavra correta.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="C"
          status="correct"
        />
        <Cell value="E" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
        <Cell value="T" isCompleted={true} />
        <Cell value="O" isCompleted={true} />
      </div>
      <p className="text-sm text-white dark:text-gray-300">
        A letra C está na palavra e posição correta.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="Q" isCompleted={true} />
        <Cell value="U" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="A"
          status="present"
        />
        <Cell value="S" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
      </div>
      <p className="text-sm text-white dark:text-gray-300">
        A letra A está na palavra, mas está na posição errada.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="F" isCompleted={true} />
        <Cell value="A" isCompleted={true} />
        <Cell value="L" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="H" status="absent" />
        <Cell value="O" isCompleted={true} />
      </div>
      <p className="text-sm text-white dark:text-gray-300">
        A letra H não tem em nenhuma posição da palavra.
      </p>

      <p className="mt-6 text-sm italic text-white dark:text-gray-300">
        A palavra correta está nas escrituras sagradas ! (Baseado na versão Almeida Revista e Atualizada) Já leu a Bíblia hoje ?
      </p>
    </BaseModal>
  )
}
