export const VALID_GUESSES = [
 'aaiun',
 'aarao',
 'aarel',
 'ababa',
 'abaca',
 'abaco',
 'abada',
 'abade',
 'abafa',
 'abafe',
 'abafo',
 'abais',
 'abala',
 'abale',
 'abalo',
 'abana',
 'abane',
 'abano',
 'abare',
 'abata',
 'abate',
 'abati',
 'abato',
 'abdal',
 'abdao',
 'abdom',
 'abdon',
 'abeau',
 'abeba',
 'abece',
 'abela',
 'abete',
 'abeto',
 'abglt',
 'abias',
 'abibe',
 'abida',
 'abiel',
 'abios',
 'abiud',
 'ablua',
 'ablui',
 'abluo',
 'abner',
 'abole',
 'aboli',
 'abono',
 'abram',
 'abrao',
 'abrar',
 'abras',
 'abrem',
 'abres',
 'abreu',
 'abria',
 'abril',
 'abrir',
 'abris',
 'abriu',
 'abste',
 'abuja',
 'abulo',
 'abusa',
 'abuse',
 'abuso',
 'abuta',
 'acaba',
 'acabe',
 'acabo',
 'acaca',
 'acacu',
 'acade',
 'acaia',
 'acais',
 'acaju',
 'acana',
 'acapu',
 'acara',
 'acari',
 'acaro',
 'acaru',
 'acaso',
 'acata',
 'acate',
 'acato',
 'acaua',
 'acbor',
 'accio',
 'acena',
 'acene',
 'aceno',
 'acesa',
 'aceso',
 'aceto',
 'achai',
 'acham',
 'achar',
 'achas',
 'achei',
 'achem',
 'aches',
 'achoa',
 'achou',
 'acica',
 'acida',
 'acido',
 'acima',
 'acino',
 'acker',
 'acode',
 'acoes',
 'acola',
 'acoli',
 'acral',
 'acres',
 'acuai',
 'acuam',
 'acuar',
 'acuas',
 'acube',
 'acuda',
 'acude',
 'acudi',
 'acudo',
 'acuei',
 'acuem',
 'acues',
 'acuma',
 'acuna',
 'acuos',
 'acuou',
 'acusa',
 'acuse',
 'acuso',
 'acuta',
 'acuti',
 'adada',
 'adaga',
 'adage',
 'adail',
 'adair',
 'adama',
 'adami',
 'adara',
 'adari',
 'adega',
 'adele',
 'adema',
 'adena',
 'adeno',
 'adere',
 'aderi',
 'adeso',
 'adeus',
 'adiai',
 'adiam',
 'adiar',
 'adias',
 'adida',
 'adido',
 'adiei',
 'adiel',
 'adiem',
 'adies',
 'adila',
 'adimo',
 'adina',
 'adino',
 'adiou',
 'adipo',
 'adira',
 'adiro',
 'adita',
 'adite',
 'adito',
 'adlai',
 'adler',
 'adoca',
 'adoce',
 'adoco',
 'adolf',
 'adoni',
 'adora',
 'adore',
 'adoro',
 'adota',
 'adote',
 'adoto',
 'adova',
 'adqui',
 'adson',
 'aduba',
 'adube',
 'adubo',
 'adufe',
 'adula',
 'adule',
 'adulo',
 'advem',
 'advim',
 'advir',
 'aecio',
 'aedes',
 'aegla',
 'aelia',
 'aerea',
 'aereo',
 'afaga',
 'afago',
 'afana',
 'afane',
 'afano',
 'afear',
 'afeca',
 'afega',
 'afere',
 'aferi',
 'afeta',
 'afete',
 'afeto',
 'afiai',
 'afiam',
 'afiar',
 'afias',
 'afido',
 'afiei',
 'afiem',
 'afies',
 'afilo',
 'afina',
 'afine',
 'afino',
 'afins',
 'afiou',
 'afira',
 'afiro',
 'afixa',
 'afixe',
 'afixo',
 'aflar',
 'aflua',
 'aflui',
 'afluo',
 'afoba',
 'afobe',
 'afobo',
 'afofa',
 'afofe',
 'afofo',
 'afoga',
 'afogo',
 'afono',
 'afora',
 'afore',
 'aforo',
 'afoxe',
 'afros',
 'aftas',
 'afura',
 'agabo',
 'agada',
 'agama',
 'agame',
 'agape',
 'agata',
 'agate',
 'agave',
 'ageis',
 'ageno',
 'agiam',
 'agias',
 'agido',
 'agimo',
 'agina',
 'agios',
 'agira',
 'agita',
 'agite',
 'agito',
 'aglae',
 'agnes',
 'agoge',
 'agora',
 'agori',
 'agrar',
 'aguai',
 'aguam',
 'aguar',
 'aguas',
 'aguca',
 'aguce',
 'aguco',
 'aguda',
 'agudo',
 'aguei',
 'aguem',
 'agues',
 'aguia',
 'aguou',
 'aguti',
 'aiate',
 'aicao',
 'aicar',
 'aigue',
 'aimee',
 'ainda',
 'ainoa',
 'aipim',
 'aipos',
 'airar',
 'aires',
 'aisar',
 'aiude',
 'ajais',
 'ajaja',
 'ajamo',
 'ajuda',
 'ajude',
 'ajudo',
 'ajufe',
 'ajuru',
 'alabe',
 'alada',
 'alado',
 'alaga',
 'alago',
 'alain',
 'alair',
 'alamo',
 'alane',
 'alano',
 'alaor',
 'albin',
 'albis',
 'albom',
 'albor',
 'album',
 'alcai',
 'alcam',
 'alcao',
 'alcar',
 'alcas',
 'alcei',
 'alcem',
 'alces',
 'alceu',
 'alcir',
 'alcou',
 'aldea',
 'alder',
 'aldir',
 'aldol',
 'alega',
 'alego',
 'aleia',
 'aleja',
 'alelo',
 'alema',
 'alena',
 'alepo',
 'alerj',
 'aleto',
 'alexa',
 'alfeu',
 'alfim',
 'alfio',
 'algal',
 'algar',
 'algas',
 'alges',
 'algia',
 'algol',
 'algor',
 'algoz',
 'algum',
 'alhar',
 'alhas',
 'alhea',
 'alheo',
 'alhos',
 'aliai',
 'aliam',
 'aliar',
 'alias',
 'alibi',
 'alica',
 'alice',
 'alico',
 'alida',
 'aliei',
 'aliem',
 'alies',
 'alija',
 'alije',
 'alijo',
 'aline',
 'aliou',
 'alisa',
 'alise',
 'aliso',
 'alita',
 'almas',
 'almir',
 'almom',
 'aloca',
 'aloco',
 'aloes',
 'alogo',
 'aloja',
 'aloje',
 'alojo',
 'alote',
 'alpes',
 'altar',
 'altas',
 'altea',
 'alter',
 'altor',
 'altos',
 'aluan',
 'aluar',
 'aluco',
 'aluda',
 'alude',
 'aludi',
 'aludo',
 'alufa',
 'aluga',
 'alugo',
 'aluir',
 'alula',
 'aluna',
 'aluno',
 'alura',
 'alvao',
 'alvar',
 'alvas',
 'alveo',
 'alves',
 'alvim',
 'alvor',
 'alvos',
 'amaca',
 'amaci',
 'amada',
 'amade',
 'amado',
 'amadu',
 'amago',
 'amais',
 'amala',
 'amamo',
 'amamu',
 'amani',
 'amapa',
 'amara',
 'amari',
 'amaro',
 'amasa',
 'amava',
 'ambao',
 'ambar',
 'ambas',
 'amber',
 'ambos',
 'ambre',
 'ameba',
 'ameca',
 'ameia',
 'ameis',
 'amelo',
 'amemo',
 'amena',
 'ameno',
 'amico',
 'amido',
 'amiel',
 'amiga',
 'amigo',
 'amilo',
 'amima',
 'amime',
 'amimo',
 'amino',
 'amito',
 'amiur',
 'amnio',
 'amnom',
 'amola',
 'amole',
 'amolo',
 'amora',
 'amore',
 'ampla',
 'amplo',
 'amuos',
 'amura',
 'amuso',
 'anabe',
 'anaca',
 'anace',
 'anael',
 'anage',
 'anahy',
 'anaia',
 'anais',
 'anaja',
 'anama',
 'aname',
 'anami',
 'anana',
 'anani',
 'anano',
 'anapu',
 'anari',
 'anate',
 'anaua',
 'anaue',
 'anbar',
 'ancas',
 'ancel',
 'ancia',
 'ancil',
 'andai',
 'andam',
 'andar',
 'andas',
 'andei',
 'andem',
 'andes',
 'andir',
 'andor',
 'andou',
 'andre',
 'aneci',
 'aneis',
 'anejo',
 'anela',
 'anele',
 'anelo',
 'anema',
 'anete',
 'aneto',
 'anexa',
 'anexe',
 'anexo',
 'angas',
 'angel',
 'anglo',
 'angor',
 'angra',
 'angul',
 'aniao',
 'aniel',
 'anima',
 'anime',
 'animi',
 'animo',
 'anion',
 'aniso',
 'anito',
 'anjos',
 'anjum',
 'anodo',
 'anoes',
 'anona',
 'anoso',
 'anota',
 'anote',
 'anoto',
 'anrao',
 'ansel',
 'ansia',
 'antao',
 'antar',
 'antas',
 'antes',
 'anteu',
 'antro',
 'anual',
 'anuam',
 'anuar',
 'anuas',
 'anube',
 'anuem',
 'anuia',
 'anuir',
 'anuis',
 'anuiu',
 'anuja',
 'anula',
 'anule',
 'anulo',
 'anuns',
 'anuro',
 'anzol',
 'aoita',
 'aonde',
 'aonio',
 'aorta',
 'apaga',
 'apago',
 'apaim',
 'apana',
 'apara',
 'apare',
 'aparo',
 'apart',
 'apeai',
 'apear',
 'apeei',
 'apega',
 'apego',
 'apeia',
 'apeie',
 'apeio',
 'apela',
 'apele',
 'apelo',
 'apena',
 'apene',
 'apeno',
 'apeou',
 'apiai',
 'apice',
 'apiol',
 'apita',
 'apite',
 'apito',
 'aplao',
 'apoca',
 'apodi',
 'apodo',
 'apoia',
 'apoie',
 'apoio',
 'apolo',
 'apore',
 'aporo',
 'aptar',
 'aptas',
 'aptos',
 'apupo',
 'apura',
 'apure',
 'apuro',
 'aquem',
 'aqueu',
 'aquim',
 'aquis',
 'araao',
 'araba',
 'arabe',
 'arabu',
 'araca',
 'arace',
 'aracu',
 'arada',
 'arade',
 'arado',
 'arais',
 'arame',
 'aramo',
 'arani',
 'arara',
 'arare',
 'arato',
 'aratu',
 'araua',
 'araue',
 'arava',
 'aravo',
 'araxa',
 'araza',
 'arbel',
 'arcai',
 'arcam',
 'arcao',
 'arcar',
 'arcas',
 'arcaz',
 'arcos',
 'arcou',
 'ardam',
 'ardas',
 'ardei',
 'ardem',
 'arder',
 'ardes',
 'ardeu',
 'ardia',
 'ardil',
 'ardis',
 'ardom',
 'ardor',
 'ardra',
 'ardua',
 'arduo',
 'areal',
 'areao',
 'arear',
 'areas',
 'areca',
 'areia',
 'areio',
 'areis',
 'areja',
 'areje',
 'arejo',
 'areli',
 'arelo',
 'aremo',
 'arena',
 'areno',
 'arfai',
 'arfam',
 'arfar',
 'arfas',
 'arfei',
 'arfem',
 'arfes',
 'arfou',
 'argas',
 'argel',
 'argem',
 'arges',
 'argeu',
 'argol',
 'argon',
 'argos',
 'argua',
 'argue',
 'argui',
 'arguo',
 'ariar',
 'arias',
 'arica',
 'arida',
 'arido',
 'ariel',
 'aries',
 'arigo',
 'arilo',
 'arimo',
 'arina',
 'ariri',
 'arita',
 'arite',
 'arito',
 'arlei',
 'arles',
 'armai',
 'armam',
 'armar',
 'armas',
 'armei',
 'armem',
 'armes',
 'armia',
 'armou',
 'arnes',
 'arnom',
 'arode',
 'arodi',
 'aroer',
 'aroma',
 'aroto',
 'arpao',
 'arpar',
 'arpeu',
 'arpoa',
 'arpoe',
 'arpoo',
 'arque',
 'arqui',
 'arras',
 'arrea',
 'arria',
 'arrie',
 'arrio',
 'arroz',
 'artes',
 'artur',
 'aruai',
 'aruas',
 'aruba',
 'aruja',
 'arula',
 'aruma',
 'arupa',
 'asael',
 'asafe',
 'asana',
 'asbel',
 'ascua',
 'asera',
 'asiel',
 'asila',
 'asile',
 'asilo',
 'asima',
 'asnal',
 'asnos',
 'aspar',
 'aspas',
 'assai',
 'assam',
 'assar',
 'assas',
 'assaz',
 'assea',
 'assei',
 'assem',
 'asses',
 'assim',
 'assir',
 'assis',
 'assoa',
 'assoe',
 'assoo',
 'assos',
 'assou',
 'assur',
 'aster',
 'astor',
 'astre',
 'astro',
 'asura',
 'ataca',
 'ataco',
 'atada',
 'atade',
 'atado',
 'ataga',
 'atais',
 'atalo',
 'atamo',
 'atara',
 'atare',
 'atava',
 'ateai',
 'atear',
 'ateei',
 'ateia',
 'ateie',
 'ateio',
 'ateis',
 'atemo',
 'atena',
 'atens',
 'ateou',
 'atera',
 'ateus',
 'ateve',
 'atica',
 'atice',
 'atico',
 'atido',
 'atila',
 'atimo',
 'atina',
 'atine',
 'atino',
 'atira',
 'atire',
 'atiro',
 'ativa',
 'ative',
 'ativo',
 'atlai',
 'atlas',
 'atoba',
 'atola',
 'atole',
 'atolo',
 'atomo',
 'atona',
 'atono',
 'atoto',
 'atrai',
 'atras',
 'atreu',
 'atril',
 'atrio',
 'atriz',
 'atroz',
 'atuai',
 'atual',
 'atuam',
 'atuar',
 'atuas',
 'atuei',
 'atuem',
 'atues',
 'atuns',
 'atuou',
 'atura',
 'ature',
 'aturo',
 'au-au',
 'audaz',
 'audio',
 'aueti',
 'aulas',
 'aumai',
 'aural',
 'aurar',
 'auras',
 'aurea',
 'aureo',
 'aurir',
 'autor',
 'autos',
 'autua',
 'autue',
 'autuo',
 'auzao',
 'avais',
 'avant',
 'avara',
 'avaro',
 'avati',
 'aveia',
 'aveio',
 'avela',
 'avena',
 'avens',
 'aveus',
 'aviai',
 'aviam',
 'aviao',
 'aviar',
 'avias',
 'avida',
 'avido',
 'aviei',
 'aviem',
 'avier',
 'avies',
 'avila',
 'avimo',
 'avios',
 'aviou',
 'avira',
 'avisa',
 'avise',
 'aviso',
 'avite',
 'avium',
 'aviva',
 'avive',
 'avivo',
 'axexe',
 'axial',
 'axila',
 'axixa',
 'azaca',
 'azado',
 'azael',
 'azara',
 'azare',
 'azaro',
 'azeca',
 'azeda',
 'azede',
 'azedo',
 'azeri',
 'aziar',
 'azias',
 'azida',
 'aziel',
 'azigo',
 'azimo',
 'azina',
 'azize',
 'azmom',
 'azoia',
 'azoto',
 'azuba',
 'azuis',
 'azula',
 'azule',
 'azulo',
 'baala',
 'baana',
 'baara',
 'baasa',
 'babai',
 'babam',
 'babao',
 'babar',
 'babas',
 'babau',
 'babei',
 'babel',
 'babem',
 'baber',
 'babes',
 'babis',
 'bable',
 'babou',
 'bacal',
 'bacar',
 'bacen',
 'bachi',
 'bacia',
 'bacio',
 'bacon',
 'bacos',
 'bacta',
 'badal',
 'badia',
 'badri',
 'baena',
 'baeta',
 'bafar',
 'bafer',
 'bafio',
 'bafos',
 'bagas',
 'bagda',
 'bagem',
 'bagos',
 'bagre',
 'bagua',
 'bague',
 'bahia',
 'baiao',
 'baiar',
 'baias',
 'baila',
 'baile',
 'bailo',
 'baios',
 'baira',
 'baita',
 'baite',
 'baixa',
 'baixe',
 'baixo',
 'bajar',
 'balam',
 'balao',
 'balar',
 'balas',
 'balca',
 'balde',
 'balea',
 'balem',
 'bales',
 'balia',
 'balim',
 'balir',
 'balis',
 'baliu',
 'balsa',
 'balso',
 'balta',
 'balto',
 'bamba',
 'bambi',
 'bambo',
 'bambu',
 'banal',
 'banar',
 'banca',
 'banco',
 'banda',
 'bando',
 'banem',
 'banes',
 'bangu',
 'banha',
 'banhe',
 'banho',
 'bania',
 'banir',
 'banis',
 'baniu',
 'banjo',
 'banto',
 'bantu',
 'banze',
 'baoba',
 'baque',
 'barao',
 'baras',
 'barba',
 'barbi',
 'barbo',
 'barca',
 'barco',
 'bardo',
 'barea',
 'barem',
 'bares',
 'baria',
 'baril',
 'bario',
 'baroa',
 'barra',
 'barre',
 'barro',
 'barsa',
 'basal',
 'basar',
 'basco',
 'basea',
 'bases',
 'basio',
 'basis',
 'basra',
 'basse',
 'basso',
 'basta',
 'baste',
 'basto',
 'batam',
 'batao',
 'batas',
 'batei',
 'batel',
 'batem',
 'bater',
 'bates',
 'bateu',
 'batia',
 'batom',
 'bator',
 'batos',
 'bauna',
 'bauru',
 'bavio',
 'baxim',
 'bazar',
 'beaba',
 'beata',
 'beato',
 'bebai',
 'bebam',
 'bebas',
 'bebei',
 'bebel',
 'bebem',
 'beber',
 'bebes',
 'bebeu',
 'bebia',
 'bebum',
 'becas',
 'becos',
 'bedel',
 'bedem',
 'begum',
 'beico',
 'beija',
 'beije',
 'beijo',
 'beiju',
 'beira',
 'beire',
 'beiro',
 'bejel',
 'belas',
 'belem',
 'belfo',
 'belga',
 'belgo',
 'belho',
 'bello',
 'belos',
 'bemol',
 'benca',
 'benda',
 'bende',
 'bengo',
 'benim',
 'benin',
 'benta',
 'bento',
 'benza',
 'benze',
 'benzi',
 'benzo',
 'beque',
 'berco',
 'berda',
 'bergo',
 'berma',
 'berna',
 'berne',
 'berol',
 'berra',
 'berre',
 'berro',
 'berta',
 'berto',
 'besai',
 'besta',
 'betao',
 'betar',
 'betel',
 'betim',
 'betis',
 'betle',
 'betum',
 'biaba',
 'biana',
 'biara',
 'biari',
 'bibra',
 'bicai',
 'bical',
 'bicam',
 'bicao',
 'bicar',
 'bicas',
 'bicha',
 'bicho',
 'bicos',
 'bicou',
 'biela',
 'bielo',
 'biene',
 'bigas',
 'bigua',
 'bigue',
 'bijou',
 'bilac',
 'bilar',
 'bilha',
 'bilis',
 'bilro',
 'bilsa',
 'bimba',
 'binar',
 'binde',
 'binea',
 'bingo',
 'binha',
 'binho',
 'binui',
 'bioma',
 'biose',
 'biota',
 'bipar',
 'bique',
 'birma',
 'birra',
 'birro',
 'birsa',
 'birus',
 'bisao',
 'bisar',
 'bisel',
 'bisol',
 'bispo',
 'bisso',
 'biter',
 'bitia',
 'blase',
 'blefa',
 'blefe',
 'blefo',
 'blitz',
 'bloco',
 'blues',
 'blusa',
 'boate',
 'boato',
 'bobao',
 'bobas',
 'bobea',
 'bobos',
 'bocal',
 'bocao',
 'bocar',
 'bocas',
 'bocel',
 'bocha',
 'boche',
 'bocio',
 'bodas',
 'bodes',
 'bodum',
 'boers',
 'bofas',
 'bofes',
 'bogas',
 'bogum',
 'boiai',
 'boiam',
 'boiao',
 'boiar',
 'boias',
 'boiei',
 'boiem',
 'boies',
 'boina',
 'boiou',
 'boita',
 'boite',
 'bojar',
 'bojui',
 'bolai',
 'bolam',
 'bolao',
 'bolar',
 'bolas',
 'bolbo',
 'bolco',
 'boldo',
 'bolei',
 'bolem',
 'boles',
 'boleu',
 'bolha',
 'bolim',
 'bolor',
 'bolos',
 'bolou',
 'bolsa',
 'bolso',
 'bomba',
 'bombo',
 'bonar',
 'bonde',
 'bondi',
 'bones',
 'bongo',
 'bonus',
 'bonze',
 'bonzo',
 'boral',
 'borax',
 'borba',
 'borda',
 'borde',
 'bordo',
 'borel',
 'boris',
 'borja',
 'borla',
 'borne',
 'borno',
 'borra',
 'borre',
 'borro',
 'bosca',
 'bosco',
 'boson',
 'bossa',
 'bosta',
 'botai',
 'botam',
 'botao',
 'botar',
 'botas',
 'botei',
 'botem',
 'botes',
 'botim',
 'botos',
 'botou',
 'botox',
 'bouba',
 'bovis',
 'boxea',
 'bozra',
 'braba',
 'brabo',
 'braca',
 'braco',
 'brada',
 'brade',
 'bradi',
 'brado',
 'braga',
 'brama',
 'brame',
 'brami',
 'bramo',
 'brasa',
 'brava',
 'bravo',
 'brear',
 'breca',
 'breco',
 'bredo',
 'brega',
 'brejo',
 'brema',
 'breno',
 'breta',
 'brete',
 'breus',
 'breve',
 'brial',
 'brica',
 'brico',
 'brics',
 'brida',
 'briga',
 'brigo',
 'brins',
 'briol',
 'brios',
 'brisa',
 'brita',
 'brito',
 'briza',
 'broas',
 'broca',
 'broco',
 'bromo',
 'brota',
 'brote',
 'broto',
 'bruaa',
 'bruce',
 'bruma',
 'bruno',
 'bruta',
 'bruto',
 'bruxa',
 'bruxo',
 'buada',
 'buaiz',
 'bubao',
 'bucal',
 'bucha',
 'bucho',
 'buchu',
 'bucle',
 'budur',
 'buena',
 'bueno',
 'bufai',
 'bufam',
 'bufao',
 'bufar',
 'bufas',
 'bufei',
 'bufem',
 'bufes',
 'bufos',
 'bufou',
 'bugio',
 'bugle',
 'bugre',
 'bujao',
 'bulam',
 'bular',
 'bulas',
 'bulbo',
 'buldo',
 'bules',
 'bulia',
 'bulir',
 'bulis',
 'buliu',
 'bumba',
 'bumbo',
 'bunda',
 'bunde',
 'bundo',
 'buque',
 'buqui',
 'burca',
 'burdo',
 'burel',
 'burgo',
 'buril',
 'burla',
 'burle',
 'burlo',
 'burra',
 'burro',
 'bursa',
 'busca',
 'busco',
 'busto',
 'butao',
 'butia',
 'butil',
 'butim',
 'butio',
 'buxos',
 'buzio',
 'caaba',
 'caapi',
 'cabal',
 'cabas',
 'cabau',
 'cabaz',
 'cabei',
 'cabem',
 'caber',
 'cabes',
 'cabia',
 'cabom',
 'cabos',
 'caboz',
 'cabra',
 'cabul',
 'cacai',
 'cacam',
 'cacao',
 'cacar',
 'cacas',
 'cacau',
 'cacei',
 'cacem',
 'caces',
 'cache',
 'cacho',
 'cacoa',
 'cacoe',
 'cacoo',
 'cacos',
 'cacou',
 'cacto',
 'cacua',
 'cades',
 'caete',
 'cafes',
 'cafre',
 'cafta',
 'cafua',
 'cafuz',
 'cagai',
 'cagam',
 'cagao',
 'cagar',
 'cagas',
 'cagou',
 'cague',
 'caiai',
 'caiam',
 'caiar',
 'caias',
 'caiba',
 'caibi',
 'caibo',
 'caico',
 'caida',
 'caide',
 'caido',
 'caiei',
 'caiem',
 'caies',
 'caimo',
 'caina',
 'caiou',
 'caipa',
 'caira',
 'cairo',
 'cairu',
 'caite',
 'caiua',
 'caius',
 'caixa',
 'caixe',
 'cajas',
 'cajui',
 'cajus',
 'calai',
 'calam',
 'calao',
 'calar',
 'calas',
 'calau',
 'calca',
 'calce',
 'calco',
 'calda',
 'caldo',
 'calei',
 'calem',
 'cales',
 'calha',
 'calhe',
 'calho',
 'calil',
 'calix',
 'calma',
 'calmo',
 'calne',
 'caloi',
 'calom',
 'calor',
 'calos',
 'calou',
 'calum',
 'calva',
 'calvo',
 'camal',
 'camao',
 'camas',
 'cambe',
 'camim',
 'camom',
 'campo',
 'canaa',
 'canal',
 'canas',
 'canca',
 'candi',
 'cando',
 'canga',
 'canha',
 'canho',
 'canil',
 'canja',
 'canje',
 'canjo',
 'canoa',
 'canoe',
 'canon',
 'canos',
 'cansa',
 'canse',
 'canso',
 'canta',
 'cante',
 'canto',
 'cantu',
 'canza',
 'caoba',
 'caobi',
 'capai',
 'capam',
 'capao',
 'capar',
 'capas',
 'capaz',
 'capei',
 'capem',
 'capes',
 'capia',
 'capim',
 'capom',
 'capou',
 'capra',
 'capso',
 'capta',
 'capte',
 'capto',
 'capua',
 'caput',
 'capuz',
 'caqui',
 'caraa',
 'carai',
 'caral',
 'carao',
 'caras',
 'carbo',
 'carca',
 'cardo',
 'carea',
 'caren',
 'carga',
 'cargo',
 'caria',
 'carie',
 'caril',
 'cario',
 'caris',
 'cariz',
 'carla',
 'carlo',
 'carme',
 'carmi',
 'carmo',
 'carne',
 'caroa',
 'carol',
 'caros',
 'carpa',
 'carpe',
 'carpi',
 'carpo',
 'carre',
 'carro',
 'carta',
 'carua',
 'casai',
 'casal',
 'casam',
 'casao',
 'casar',
 'casas',
 'casba',
 'casca',
 'casco',
 'casei',
 'casem',
 'cases',
 'casos',
 'casou',
 'caspa',
 'cassa',
 'casse',
 'casso',
 'casta',
 'casti',
 'casto',
 'catai',
 'catam',
 'catao',
 'catar',
 'catas',
 'catau',
 'catei',
 'catem',
 'cates',
 'catia',
 'catou',
 'catre',
 'catua',
 'caubi',
 'cauda',
 'cauim',
 'caule',
 'cauna',
 'caupi',
 'cauri',
 'causa',
 'cause',
 'causo',
 'cauta',
 'cauto',
 'cauxi',
 'cavai',
 'cavam',
 'cavar',
 'cavas',
 'cavei',
 'cavem',
 'caves',
 'cavia',
 'cavie',
 'cavio',
 'cavou',
 'cciii',
 'ce-ce',
 'ceado',
 'ceais',
 'ceamo',
 'ceara',
 'ceava',
 'cecal',
 'cecao',
 'cecil',
 'cecum',
 'cedam',
 'cedar',
 'cedas',
 'cedei',
 'cedem',
 'ceder',
 'cedes',
 'cedeu',
 'cedia',
 'cedro',
 'ceeis',
 'ceemo',
 'cefas',
 'cefet',
 'cegai',
 'cegam',
 'cegar',
 'cegas',
 'cegos',
 'cegou',
 'cegue',
 'ceiam',
 'ceias',
 'ceibo',
 'ceiem',
 'ceies',
 'ceifa',
 'ceife',
 'ceifo',
 'ceira',
 'ceita',
 'celas',
 'celga',
 'celha',
 'celia',
 'celie',
 'celio',
 'celso',
 'celta',
 'celte',
 'ceman',
 'cenas',
 'cenda',
 'cendi',
 'cenho',
 'cenio',
 'censo',
 'centi',
 'cento',
 'ceras',
 'cerca',
 'cerce',
 'cerco',
 'cerda',
 'cerdo',
 'cereo',
 'cerio',
 'cerne',
 'cerni',
 'cerol',
 'cerra',
 'cerre',
 'cerro',
 'certa',
 'certo',
 'cerva',
 'cervo',
 'cesar',
 'cesca',
 'cesil',
 'cesio',
 'cessa',
 'cesse',
 'cesso',
 'cesta',
 'cesto',
 'cetal',
 'cetes',
 'cetil',
 'cetim',
 'cetol',
 'cetro',
 'ceuta',
 'cevar',
 'chabu',
 'chaco',
 'chada',
 'chade',
 'chaga',
 'chago',
 'chaim',
 'chaja',
 'chale',
 'chama',
 'chame',
 'chamo',
 'chana',
 'chaos',
 'chapa',
 'chape',
 'chari',
 'charo',
 'chata',
 'chato',
 'chaua',
 'chaui',
 'chave',
 'chavo',
 'checa',
 'checo',
 'chefa',
 'chefe',
 'chega',
 'chego',
 'cheia',
 'cheio',
 'chela',
 'chesf',
 'chiai',
 'chiam',
 'chiar',
 'chias',
 'chibe',
 'chibo',
 'chica',
 'chico',
 'chiei',
 'chiem',
 'chies',
 'chila',
 'chile',
 'chili',
 'chilo',
 'chima',
 'chimu',
 'china',
 'ching',
 'chino',
 'chiou',
 'chiru',
 'chita',
 'choca',
 'choco',
 'choes',
 'chole',
 'cholo',
 'chona',
 'chope',
 'chopi',
 'chopp',
 'chora',
 'chore',
 'choro',
 'choti',
 'chova',
 'chove',
 'chovi',
 'chovo',
 'chuca',
 'chuco',
 'chule',
 'chulo',
 'chupa',
 'chupe',
 'chupo',
 'churi',
 'chuta',
 'chute',
 'chuto',
 'chuva',
 'ciano',
 'ciber',
 'cibum',
 'ciclo',
 'cidra',
 'cidro',
 'cifra',
 'cifre',
 'cifro',
 'cilas',
 'cilea',
 'cilha',
 'cilio',
 'cimol',
 'cinco',
 'cinda',
 'cinde',
 'cindi',
 'cindo',
 'cinge',
 'cingi',
 'cinja',
 'cinjo',
 'cinta',
 'cinto',
 'cinza',
 'cioba',
 'ciona',
 'ciosa',
 'cioso',
 'cipos',
 'circo',
 'cirio',
 'cirne',
 'cirro',
 'cisao',
 'cisar',
 'cisca',
 'cisco',
 'cisel',
 'cisma',
 'cisme',
 'cismo',
 'cisne',
 'ciste',
 'citai',
 'citam',
 'citar',
 'citas',
 'citei',
 'citem',
 'cites',
 'citou',
 'citro',
 'ciume',
 'civel',
 'civil',
 'civis',
 'clade',
 'clado',
 'clama',
 'clame',
 'clamo',
 'clara',
 'claro',
 'clava',
 'cleia',
 'clero',
 'clete',
 'cleto',
 'clima',
 'cline',
 'clino',
 'clipe',
 'clips',
 'clito',
 'clone',
 'cloro',
 'clube',
 'cluso',
 'cnido',
 'coada',
 'coado',
 'coagi',
 'coais',
 'coaja',
 'coajo',
 'coamo',
 'coara',
 'coari',
 'coata',
 'coate',
 'coava',
 'coaxa',
 'coaxe',
 'coaxo',
 'cobra',
 'cobre',
 'cobri',
 'cobro',
 'cocai',
 'cocal',
 'cocam',
 'cocao',
 'cocar',
 'cocas',
 'cocei',
 'cocem',
 'coces',
 'cocho',
 'cocoa',
 'cocos',
 'cocou',
 'cocre',
 'codao',
 'codea',
 'codex',
 'codio',
 'codon',
 'coege',
 'coeis',
 'coemo',
 'coesa',
 'coeso',
 'coeva',
 'coevo',
 'cofap',
 'cofio',
 'cofre',
 'coger',
 'coiba',
 'coibe',
 'coibi',
 'coibo',
 'coice',
 'coifa',
 'coios',
 'coiro',
 'coisa',
 'coite',
 'coito',
 'colai',
 'colam',
 'colar',
 'colas',
 'colau',
 'colei',
 'colem',
 'coles',
 'colha',
 'colhe',
 'colhi',
 'colho',
 'colon',
 'color',
 'colos',
 'colou',
 'colpo',
 'colza',
 'comam',
 'comas',
 'combe',
 'combi',
 'combo',
 'comei',
 'comem',
 'comer',
 'comes',
 'comeu',
 'comia',
 'comio',
 'compo',
 'comua',
 'comum',
 'conap',
 'conca',
 'conde',
 'cones',
 'conga',
 'congo',
 'conho',
 'conio',
 'conta',
 'conte',
 'conto',
 'copal',
 'copar',
 'copas',
 'copel',
 'copia',
 'copie',
 'copio',
 'copla',
 'copos',
 'copta',
 'copte',
 'copto',
 'corai',
 'coral',
 'coram',
 'corao',
 'corar',
 'coras',
 'corba',
 'corca',
 'corco',
 'corda',
 'cordo',
 'corei',
 'corem',
 'cores',
 'coreu',
 'corgo',
 'corio',
 'corja',
 'cormo',
 'corne',
 'corno',
 'coroa',
 'coroe',
 'coroo',
 'coros',
 'corou',
 'corpo',
 'corra',
 'corre',
 'corri',
 'corro',
 'corsi',
 'corso',
 'corta',
 'corte',
 'corti',
 'corto',
 'corvo',
 'cosam',
 'cosas',
 'cosbi',
 'cosei',
 'cosem',
 'coser',
 'coses',
 'coseu',
 'cosia',
 'cosme',
 'cosmo',
 'cospe',
 'cossi',
 'cosso',
 'costa',
 'costo',
 'cotai',
 'cotam',
 'cotao',
 'cotar',
 'cotas',
 'cotei',
 'cotem',
 'cotes',
 'cotos',
 'cotou',
 'cotra',
 'coube',
 'couce',
 'coupe',
 'couro',
 'cousa',
 'couto',
 'couve',
 'coval',
 'covas',
 'covil',
 'coxal',
 'coxao',
 'coxas',
 'coxia',
 'coxim',
 'coxos',
 'cozam',
 'cozas',
 'cozei',
 'cozem',
 'cozer',
 'cozes',
 'cozeu',
 'cozia',
 'craca',
 'crapo',
 'crase',
 'craua',
 'crava',
 'crave',
 'cravo',
 'crede',
 'credo',
 'creem',
 'creia',
 'creio',
 'crema',
 'creme',
 'cremo',
 'crepe',
 'crera',
 'creso',
 'creta',
 'creto',
 'creve',
 'criai',
 'criam',
 'criar',
 'crias',
 'crico',
 'crido',
 'criei',
 'criem',
 'cries',
 'crime',
 'crina',
 'crino',
 'criou',
 'crise',
 'criva',
 'crive',
 'crivo',
 'croce',
 'croco',
 'croma',
 'crome',
 'cromo',
 'crono',
 'cruas',
 'crude',
 'crudo',
 'cruel',
 'cruor',
 'crupe',
 'cruza',
 'cruze',
 'cruzi',
 'cruzo',
 'cuaca',
 'cuada',
 'cuaga',
 'cuata',
 'cuati',
 'cubar',
 'cubas',
 'cubio',
 'cubos',
 'cubra',
 'cubre',
 'cubro',
 'cucas',
 'cuche',
 'cuchi',
 'cucos',
 'cueca',
 'cuele',
 'cuera',
 'cuias',
 'cuica',
 'cuida',
 'cuide',
 'cuido',
 'cuira',
 'cuite',
 'cujas',
 'cujos',
 'cujus',
 'culpa',
 'culpe',
 'culpo',
 'culta',
 'culto',
 'cumbe',
 'cumbo',
 'cumes',
 'cumim',
 'cuneo',
 'cunha',
 'cunhe',
 'cunho',
 'cunto',
 'cupim',
 'cupio',
 'cupom',
 'curai',
 'curam',
 'curao',
 'curar',
 'curas',
 'curau',
 'curca',
 'curdo',
 'curei',
 'curem',
 'cures',
 'curia',
 'curie',
 'curii',
 'curio',
 'curou',
 'curre',
 'curro',
 'curry',
 'cursa',
 'curse',
 'curso',
 'curta',
 'curte',
 'curti',
 'curto',
 'curua',
 'curul',
 'curva',
 'curve',
 'curvo',
 'cusco',
 'cuspa',
 'cuspe',
 'cuspi',
 'cuspo',
 'cusso',
 'custa',
 'custe',
 'custo',
 'cuter',
 'cutia',
 'cutis',
 'cutra',
 'cuxiu',
 'cxxii',
 'dacao',
 'dacar',
 'dacia',
 'dacio',
 'dadas',
 'dados',
 'dadra',
 'dafne',
 'dagom',
 'daima',
 'daime',
 'daiza',
 'dakar',
 'dalai',
 'dalan',
 'dalem',
 'dalgo',
 'dalia',
 'dalmo',
 'dalvo',
 'damao',
 'damas',
 'damim',
 'damos',
 'danai',
 'danam',
 'danar',
 'danas',
 'danca',
 'dance',
 'danco',
 'dande',
 'dandi',
 'dando',
 'danei',
 'danem',
 'danes',
 'danos',
 'danou',
 'danta',
 'dante',
 'daqui',
 'darao',
 'daras',
 'darci',
 'darda',
 'dardo',
 'darei',
 'darem',
 'dares',
 'daria',
 'dario',
 'darma',
 'daros',
 'dassa',
 'datai',
 'datam',
 'datar',
 'datas',
 'datei',
 'datem',
 'dates',
 'datou',
 'davam',
 'davas',
 'david',
 'deado',
 'debar',
 'debil',
 'debir',
 'decai',
 'decil',
 'decio',
 'dedal',
 'dedao',
 'dedar',
 'dedos',
 'deduz',
 'deise',
 'deita',
 'deite',
 'deito',
 'deixa',
 'deixe',
 'deixo',
 'delas',
 'deles',
 'delir',
 'delma',
 'delon',
 'delta',
 'delza',
 'demao',
 'demar',
 'demas',
 'demos',
 'dende',
 'dendo',
 'dengo',
 'denis',
 'densa',
 'denso',
 'dente',
 'depoe',
 'depor',
 'depos',
 'depus',
 'deram',
 'deras',
 'derbe',
 'derbi',
 'derem',
 'deres',
 'derme',
 'dermo',
 'derre',
 'desar',
 'desca',
 'desce',
 'desci',
 'desco',
 'desde',
 'desma',
 'desmo',
 'despe',
 'despi',
 'dessa',
 'desse',
 'desta',
 'deste',
 'detem',
 'deter',
 'deteu',
 'detre',
 'deuel',
 'deusa',
 'devam',
 'devas',
 'devei',
 'devem',
 'dever',
 'deves',
 'deveu',
 'devia',
 'devir',
 'diabo',
 'diade',
 'diafa',
 'diale',
 'diana',
 'diane',
 'diara',
 'diate',
 'diazo',
 'dibom',
 'dibri',
 'dicao',
 'dicar',
 'dicas',
 'dicha',
 'dicla',
 'diego',
 'dieno',
 'dieta',
 'digam',
 'digas',
 'digna',
 'digne',
 'digno',
 'dilea',
 'dilma',
 'dilua',
 'dilui',
 'diluo',
 'dilza',
 'dimna',
 'dimom',
 'dinar',
 'dinas',
 'dindo',
 'dingo',
 'diniz',
 'diodo',
 'diogo',
 'dioma',
 'diona',
 'dione',
 'dioso',
 'diote',
 'diple',
 'diplo',
 'dique',
 'dirao',
 'diras',
 'dirce',
 'direi',
 'diria',
 'dirra',
 'disca',
 'disco',
 'disel',
 'disom',
 'dispo',
 'disse',
 'disso',
 'dista',
 'diste',
 'disto',
 'ditai',
 'ditam',
 'ditar',
 'ditas',
 'ditei',
 'ditem',
 'dites',
 'ditos',
 'ditou',
 'divas',
 'dizei',
 'dizem',
 'dizer',
 'dizes',
 'dizia',
 'doada',
 'doado',
 'doais',
 'doamo',
 'doara',
 'doava',
 'dobar',
 'doble',
 'dobra',
 'dobre',
 'dobro',
 'docas',
 'doces',
 'docil',
 'docto',
 'dodai',
 'dodoi',
 'doeis',
 'doemo',
 'doera',
 'dofca',
 'dogma',
 'dogon',
 'dogue',
 'doiam',
 'doias',
 'doida',
 'doido',
 'dolar',
 'dolce',
 'dolma',
 'dolor',
 'domai',
 'domam',
 'domar',
 'domas',
 'dombo',
 'domei',
 'domem',
 'domes',
 'domos',
 'domou',
 'donas',
 'donde',
 'dondo',
 'dongo',
 'donia',
 'donos',
 'donte',
 'dopai',
 'dopam',
 'dopar',
 'dopas',
 'dopei',
 'dopem',
 'dopes',
 'dopou',
 'dorea',
 'dores',
 'dorio',
 'dorme',
 'dormi',
 'dorna',
 'dorso',
 'dosai',
 'dosam',
 'dosar',
 'dosas',
 'dosei',
 'dosem',
 'doses',
 'dosou',
 'dotai',
 'dotal',
 'dotam',
 'dotar',
 'dotas',
 'dotei',
 'dotem',
 'dotes',
 'dotou',
 'doudo',
 'doula',
 'doura',
 'doure',
 'douro',
 'douta',
 'douto',
 'draga',
 'drago',
 'drama',
 'drena',
 'drene',
 'dreno',
 'droga',
 'drogo',
 'drupa',
 'druso',
 'duais',
 'duana',
 'dubai',
 'dubia',
 'dubio',
 'dubla',
 'duble',
 'dublo',
 'ducal',
 'ducha',
 'ducto',
 'duela',
 'duele',
 'duelo',
 'duere',
 'dueto',
 'dulce',
 'dulia',
 'dumas',
 'dumbo',
 'dunam',
 'dunar',
 'dunas',
 'dunga',
 'dunha',
 'dunia',
 'dupla',
 'duplo',
 'duque',
 'durai',
 'dural',
 'duram',
 'durao',
 'durar',
 'duras',
 'durei',
 'durem',
 'dures',
 'durex',
 'durma',
 'durmo',
 'duros',
 'durou',
 'dutos',
 'dutra',
 'duzia',
 'duzir',
 'dzeta',
 'eanes',
 'ebano',
 'ebede',
 'ebola',
 'ebrio',
 'ecese',
 'ecler',
 'ecoai',
 'ecoam',
 'ecoar',
 'ecoas',
 'ecoei',
 'ecoem',
 'ecoes',
 'ecoou',
 'ecrom',
 'ectal',
 'eculo',
 'edeia',
 'edema',
 'edens',
 'edgar',
 'edico',
 'edila',
 'edina',
 'edipo',
 'edita',
 'edite',
 'edito',
 'edjan',
 'edmar',
 'ednei',
 'edrei',
 'edson',
 'educa',
 'educo',
 'edule',
 'eduto',
 'edvan',
 'efata',
 'efebo',
 'efeso',
 'eflal',
 'efode',
 'eforo',
 'efrem',
 'efrom',
 'egide',
 'egito',
 'eglom',
 'eguas',
 'eiche',
 'eiras',
 'eivar',
 'eixar',
 'eixos',
 'ejeta',
 'ejete',
 'ejeto',
 'elada',
 'elade',
 'elane',
 'elate',
 'elbio',
 'elcio',
 'elder',
 'eleda',
 'elefe',
 'elege',
 'elegi',
 'eleja',
 'elejo',
 'elemi',
 'eleni',
 'elepe',
 'eleva',
 'eleve',
 'elevo',
 'elias',
 'elice',
 'eliel',
 'elime',
 'elina',
 'eliri',
 'elisa',
 'elise',
 'elite',
 'eliud',
 'elixa',
 'elixe',
 'elixi',
 'elixo',
 'elmar',
 'elmer',
 'elmos',
 'eloar',
 'eloim',
 'elona',
 'elope',
 'elote',
 'elson',
 'elton',
 'eluir',
 'elvia',
 'elvis',
 'emamo',
 'emana',
 'emane',
 'emano',
 'emaus',
 'emeio',
 'emese',
 'emfim',
 'emico',
 'emile',
 'emins',
 'emita',
 'emite',
 'emiti',
 'emito',
 'emoto',
 'empar',
 'empos',
 'emula',
 'emule',
 'emulo',
 'enano',
 'encha',
 'enche',
 'enchi',
 'encho',
 'endes',
 'endor',
 'endro',
 'eneas',
 'enema',
 'enfia',
 'enfie',
 'enfim',
 'enfio',
 'engar',
 'enino',
 'enito',
 'enjoa',
 'enjoe',
 'enjoo',
 'enoja',
 'enoje',
 'enojo',
 'enora',
 'enque',
 'entao',
 'enter',
 'entes',
 'entoa',
 'entoe',
 'entoo',
 'entra',
 'entre',
 'entro',
 'enves',
 'envia',
 'envie',
 'envio',
 'enxui',
 'eolia',
 'eolio',
 'epica',
 'epico',
 'epiro',
 'epoca',
 'epodo',
 'epoxi',
 'epura',
 'equer',
 'erati',
 'erato',
 'erbio',
 'ereis',
 'erere',
 'ereta',
 'ereto',
 'ergam',
 'ergas',
 'ergia',
 'ergio',
 'ergue',
 'ergui',
 'erica',
 'erice',
 'erico',
 'erige',
 'erigi',
 'erija',
 'erijo',
 'ermal',
 'ermos',
 'eroda',
 'erode',
 'erodi',
 'erodo',
 'errai',
 'erram',
 'errar',
 'erras',
 'errei',
 'errem',
 'erres',
 'error',
 'erros',
 'errou',
 'eruca',
 'eruga',
 'erval',
 'ervar',
 'ervas',
 'esbom',
 'escoa',
 'escoe',
 'escol',
 'escoo',
 'esdra',
 'esgar',
 'esima',
 'esimo',
 'esmar',
 'esopo',
 'espia',
 'espie',
 'espio',
 'espru',
 'esqui',
 'esrom',
 'esron',
 'essas',
 'esses',
 'estai',
 'estao',
 'estar',
 'estas',
 'ester',
 'estes',
 'estio',
 'estol',
 'estom',
 'estou',
 'estro',
 'esvai',
 'etano',
 'etapa',
 'eteno',
 'ethos',
 'etica',
 'etico',
 'etilo',
 'etimo',
 'etino',
 'etipe',
 'etite',
 'etnia',
 'eudes',
 'euler',
 'eurio',
 'evada',
 'evade',
 'evadi',
 'evado',
 'evair',
 'evase',
 'evita',
 'evite',
 'evito',
 'evoca',
 'evoco',
 'evora',
 'exala',
 'exale',
 'exalo',
 'exame',
 'exara',
 'exare',
 'exaro',
 'exata',
 'exato',
 'exiba',
 'exibe',
 'exibi',
 'exibo',
 'exige',
 'exigi',
 'exija',
 'exijo',
 'exila',
 'exile',
 'exilo',
 'exima',
 'exime',
 'eximi',
 'eximo',
 'exina',
 'exito',
 'exodo',
 'expia',
 'expie',
 'expio',
 'expoe',
 'expor',
 'expos',
 'expus',
 'extra',
 'exuma',
 'exume',
 'exumo',
 'ezbai',
 'faber',
 'fabio',
 'facam',
 'facao',
 'facas',
 'faces',
 'facho',
 'facie',
 'facil',
 'facto',
 'fadar',
 'fadas',
 'fados',
 'fagia',
 'faial',
 'faiar',
 'faias',
 'faido',
 'faina',
 'faire',
 'faite',
 'faixa',
 'falai',
 'falam',
 'falar',
 'falas',
 'falaz',
 'falco',
 'falda',
 'faleg',
 'falei',
 'falem',
 'fales',
 'falha',
 'falhe',
 'falho',
 'falia',
 'falir',
 'falis',
 'faliu',
 'faloa',
 'falou',
 'falsa',
 'falso',
 'falta',
 'falte',
 'falto',
 'falua',
 'falus',
 'famas',
 'fanal',
 'fanar',
 'fando',
 'fanho',
 'fanio',
 'fante',
 'farad',
 'farao',
 'faras',
 'farda',
 'fardo',
 'farei',
 'faria',
 'farme',
 'faroe',
 'farol',
 'faros',
 'farpa',
 'farra',
 'farsa',
 'farta',
 'farte',
 'farto',
 'fases',
 'fasor',
 'fatal',
 'fatia',
 'fatie',
 'fatio',
 'fator',
 'fatos',
 'fatuo',
 'fauce',
 'fauna',
 'fauno',
 'favas',
 'favor',
 'favos',
 'faxes',
 'fazei',
 'fazem',
 'fazer',
 'fazes',
 'fazia',
 'febra',
 'febre',
 'fecal',
 'fecha',
 'feche',
 'fecho',
 'fedam',
 'fedas',
 'fedei',
 'fedem',
 'feder',
 'fedes',
 'fedeu',
 'fedia',
 'fedor',
 'fedra',
 'feias',
 'feijo',
 'feios',
 'feira',
 'feita',
 'feito',
 'feixe',
 'felar',
 'feles',
 'felix',
 'feliz',
 'femea',
 'femeo',
 'femto',
 'femur',
 'fenda',
 'fende',
 'fendi',
 'fendo',
 'fenix',
 'fenol',
 'fenos',
 'fento',
 'feral',
 'feras',
 'feraz',
 'ferem',
 'feres',
 'feria',
 'ferir',
 'feris',
 'feriu',
 'fermi',
 'fermo',
 'feroz',
 'ferpa',
 'ferra',
 'ferre',
 'ferro',
 'ferva',
 'ferve',
 'fervi',
 'fervo',
 'festa',
 'festo',
 'fetag',
 'fetal',
 'fetos',
 'fetus',
 'feudo',
 'fezes',
 'fiada',
 'fiado',
 'fiais',
 'fiamo',
 'fiapo',
 'fiara',
 'fiava',
 'fibra',
 'fibro',
 'ficai',
 'ficam',
 'ficao',
 'ficar',
 'ficas',
 'ficha',
 'fiche',
 'ficho',
 'fichu',
 'ficol',
 'ficou',
 'ficto',
 'ficus',
 'fidel',
 'fidji',
 'fieis',
 'fiemo',
 'figas',
 'figos',
 'filai',
 'filam',
 'filao',
 'filar',
 'filas',
 'filei',
 'filem',
 'filer',
 'files',
 'filha',
 'filho',
 'filia',
 'filie',
 'filio',
 'filma',
 'filme',
 'filmo',
 'filos',
 'filou',
 'final',
 'finar',
 'finas',
 'finca',
 'finco',
 'finda',
 'finde',
 'findo',
 'fines',
 'finge',
 'fingi',
 'fingo',
 'finja',
 'finjo',
 'finos',
 'finta',
 'finte',
 'finto',
 'fiofo',
 'fiote',
 'fique',
 'firam',
 'firas',
 'firma',
 'firme',
 'firmo',
 'firpo',
 'fisco',
 'fisga',
 'fisgo',
 'fisio',
 'fitai',
 'fitam',
 'fitao',
 'fitar',
 'fitas',
 'fitei',
 'fitem',
 'fites',
 'fitol',
 'fitou',
 'fixai',
 'fixam',
 'fixar',
 'fixas',
 'fixei',
 'fixem',
 'fixes',
 'fixos',
 'fixou',
 'fizer',
 'flaco',
 'flama',
 'flape',
 'flare',
 'flash',
 'flato',
 'flavo',
 'flebo',
 'fleme',
 'flexo',
 'floco',
 'flora',
 'flori',
 'fluam',
 'fluas',
 'fluem',
 'fluia',
 'fluir',
 'fluis',
 'fluiu',
 'flume',
 'fluor',
 'fluta',
 'fluxo',
 'fobia',
 'focai',
 'focal',
 'focam',
 'focar',
 'focas',
 'focos',
 'focou',
 'focus',
 'foder',
 'fofar',
 'fofas',
 'fofos',
 'fogao',
 'fogem',
 'foges',
 'fogos',
 'foice',
 'foide',
 'folar',
 'foles',
 'folga',
 'folgo',
 'folha',
 'folho',
 'folia',
 'folio',
 'fomes',
 'fomos',
 'fones',
 'fonia',
 'fonon',
 'fonte',
 'foque',
 'foral',
 'foram',
 'foras',
 'forca',
 'force',
 'forco',
 'forde',
 'forem',
 'fores',
 'foria',
 'forja',
 'forje',
 'forjo',
 'forma',
 'forme',
 'formi',
 'formo',
 'forno',
 'foros',
 'forra',
 'forre',
 'forro',
 'forte',
 'forum',
 'fosca',
 'fosco',
 'fossa',
 'fosse',
 'fosso',
 'foste',
 'fotao',
 'foton',
 'fotos',
 'fouce',
 'fovea',
 'foxim',
 'fraca',
 'fraco',
 'frade',
 'fraga',
 'frago',
 'frama',
 'frape',
 'frase',
 'freai',
 'frear',
 'freei',
 'frege',
 'freia',
 'freie',
 'freio',
 'freis',
 'freme',
 'fremi',
 'freon',
 'freou',
 'fresa',
 'frese',
 'freso',
 'freta',
 'frete',
 'freto',
 'frevo',
 'frias',
 'friez',
 'frige',
 'frigi',
 'frigo',
 'frija',
 'frijo',
 'frila',
 'frino',
 'frios',
 'frisa',
 'frise',
 'friso',
 'frita',
 'frite',
 'frito',
 'friul',
 'froco',
 'frois',
 'frota',
 'fruam',
 'fruas',
 'fruem',
 'fruia',
 'fruir',
 'fruis',
 'fruiu',
 'fruta',
 'fruto',
 'fucai',
 'fucam',
 'fucar',
 'fucas',
 'fucei',
 'fucem',
 'fuces',
 'fucou',
 'fucus',
 'fufio',
 'fugao',
 'fugas',
 'fugaz',
 'fugia',
 'fugir',
 'fugis',
 'fugiu',
 'fujam',
 'fujao',
 'fujas',
 'fujem',
 'fujes',
 'fujia',
 'fujir',
 'fujis',
 'fujiu',
 'fulao',
 'fular',
 'fulas',
 'fulge',
 'fulgi',
 'fulos',
 'fulvo',
 'fumai',
 'fumam',
 'fumao',
 'fumar',
 'fumas',
 'fumei',
 'fumem',
 'fumes',
 'fumos',
 'fumou',
 'funco',
 'funda',
 'funde',
 'fundi',
 'fundo',
 'funga',
 'funge',
 'fungo',
 'funil',
 'funis',
 'funje',
 'fuque',
 'furai',
 'fural',
 'furam',
 'furao',
 'furar',
 'furas',
 'furco',
 'furei',
 'furem',
 'fures',
 'furia',
 'furil',
 'furna',
 'furor',
 'furos',
 'furou',
 'furta',
 'furte',
 'furto',
 'fusao',
 'fusca',
 'fusco',
 'fusel',
 'fusor',
 'fusos',
 'fuste',
 'futil',
 'futum',
 'fuzil',
 'fuzis',
 'fuzue',
 'gabai',
 'gabam',
 'gabao',
 'gabar',
 'gabas',
 'gabei',
 'gabem',
 'gabes',
 'gabou',
 'gabro',
 'gados',
 'gaeta',
 'gagas',
 'gages',
 'gagos',
 'gague',
 'gaios',
 'gairo',
 'gaita',
 'gajar',
 'gajas',
 'gajos',
 'galal',
 'galao',
 'galar',
 'galas',
 'galdo',
 'gales',
 'galga',
 'galgo',
 'galha',
 'galho',
 'galia',
 'galim',
 'galio',
 'galos',
 'gamao',
 'gamba',
 'gambo',
 'gamos',
 'gamul',
 'gando',
 'gandu',
 'ganem',
 'ganes',
 'ganga',
 'ganha',
 'ganhe',
 'ganho',
 'gania',
 'ganir',
 'ganis',
 'ganiu',
 'ganja',
 'gansa',
 'ganso',
 'ganta',
 'ganza',
 'garao',
 'garbo',
 'garca',
 'garde',
 'garfa',
 'garfe',
 'garfo',
 'garis',
 'garoa',
 'garoe',
 'garoo',
 'garra',
 'gases',
 'gasmu',
 'gasta',
 'gaste',
 'gasto',
 'gatal',
 'gatao',
 'gatar',
 'gatas',
 'gatil',
 'gatos',
 'gauro',
 'gauss',
 'gavea',
 'gazal',
 'gazao',
 'gazar',
 'gazel',
 'gazeo',
 'gazua',
 'geada',
 'geado',
 'geais',
 'geamo',
 'geara',
 'gease',
 'geava',
 'geazi',
 'gebal',
 'gebar',
 'gebim',
 'geder',
 'gedor',
 'geeis',
 'geemo',
 'geena',
 'geiam',
 'geias',
 'geiem',
 'geies',
 'gelai',
 'gelam',
 'gelar',
 'gelas',
 'gelei',
 'gelem',
 'geles',
 'gelio',
 'gelos',
 'gelou',
 'gemam',
 'gemar',
 'gemas',
 'gemea',
 'gemei',
 'gemem',
 'gemeo',
 'gemer',
 'gemes',
 'gemeu',
 'gemia',
 'genal',
 'genes',
 'genia',
 'genio',
 'genoa',
 'genro',
 'gente',
 'geodo',
 'gerai',
 'geral',
 'geram',
 'gerar',
 'geras',
 'gerbi',
 'gerbo',
 'gerei',
 'gerem',
 'geres',
 'geria',
 'gerir',
 'geris',
 'geriu',
 'germe',
 'gerno',
 'geron',
 'gerou',
 'gesem',
 'geser',
 'gesio',
 'gesso',
 'gesta',
 'gesto',
 'gesur',
 'geter',
 'geuel',
 'gezer',
 'gibao',
 'gibar',
 'gibea',
 'gicle',
 'gidel',
 'gidom',
 'gigle',
 'gildo',
 'gilia',
 'gimbo',
 'gindo',
 'gines',
 'ginga',
 'ginge',
 'gingo',
 'ginja',
 'ginzo',
 'gipso',
 'girai',
 'giram',
 'girao',
 'girar',
 'giras',
 'girau',
 'girei',
 'girem',
 'gires',
 'giria',
 'girio',
 'giros',
 'girou',
 'girua',
 'gispa',
 'giteu',
 'gizar',
 'glace',
 'gleba',
 'glial',
 'glide',
 'glifo',
 'globo',
 'glomo',
 'glosa',
 'glose',
 'gloso',
 'glote',
 'gluma',
 'gluon',
 'glute',
 'gnomo',
 'gnose',
 'goano',
 'gobio',
 'godao',
 'godel',
 'godoi',
 'godos',
 'godoy',
 'goela',
 'gofer',
 'gogos',
 'gogue',
 'goias',
 'goiti',
 'goiva',
 'goivo',
 'golas',
 'golea',
 'goles',
 'golfo',
 'golpe',
 'gomas',
 'gombe',
 'gombo',
 'gomer',
 'gomes',
 'gomil',
 'gomos',
 'gondi',
 'gonga',
 'gongo',
 'gonio',
 'gonzo',
 'gorai',
 'goram',
 'gorar',
 'goras',
 'goraz',
 'gorda',
 'gordo',
 'gorei',
 'gorem',
 'gores',
 'gorga',
 'gorja',
 'gorou',
 'gorro',
 'gosen',
 'gosma',
 'gospe',
 'gosta',
 'goste',
 'gosto',
 'gotas',
 'gouda',
 'gouve',
 'gozai',
 'gozam',
 'gozao',
 'gozar',
 'gozas',
 'gozei',
 'gozem',
 'gozes',
 'gozos',
 'gozou',
 'graal',
 'grabe',
 'graca',
 'graco',
 'grade',
 'grado',
 'grafa',
 'grafe',
 'grafo',
 'grama',
 'grana',
 'grand',
 'graos',
 'grapa',
 'grata',
 'grato',
 'graus',
 'grava',
 'grave',
 'gravo',
 'graxa',
 'graxo',
 'grebo',
 'greco',
 'grega',
 'grege',
 'grego',
 'grena',
 'greva',
 'greve',
 'grias',
 'grifa',
 'grife',
 'grifo',
 'grila',
 'grile',
 'grilo',
 'grima',
 'gripe',
 'grise',
 'grisu',
 'grita',
 'grite',
 'grito',
 'grota',
 'grous',
 'gruda',
 'grude',
 'grudo',
 'grupo',
 'gruta',
 'guaca',
 'guaco',
 'guacu',
 'guado',
 'guaia',
 'guaja',
 'guaje',
 'guama',
 'guana',
 'guano',
 'guape',
 'guapo',
 'guara',
 'guaru',
 'guato',
 'guaxe',
 'guaxi',
 'guaxo',
 'gubro',
 'gueri',
 'guete',
 'gueto',
 'guiai',
 'guiam',
 'guiao',
 'guiar',
 'guias',
 'guiei',
 'guiem',
 'guies',
 'guigo',
 'guina',
 'guine',
 'guiou',
 'guira',
 'guisa',
 'guito',
 'guizo',
 'gular',
 'gumbe',
 'gumbo',
 'gumes',
 'gunda',
 'gundi',
 'gunga',
 'gural',
 'gurca',
 'guria',
 'guris',
 'gurra',
 'gusla',
 'ha-se',
 'habil',
 'habor',
 'habur',
 'hacer',
 'hacoz',
 'hadar',
 'hagar',
 'hagri',
 'haide',
 'haifa',
 'haiti',
 'hajam',
 'hajar',
 'hajas',
 'halia',
 'halim',
 'halos',
 'halul',
 'halux',
 'hamal',
 'hamas',
 'hamom',
 'hamor',
 'hamul',
 'handa',
 'hanes',
 'hanoi',
 'hanum',
 'haoma',
 'hapax',
 'harao',
 'haras',
 'harem',
 'harim',
 'haroe',
 'harpa',
 'harsa',
 'harto',
 'hartz',
 'harum',
 'harur',
 'hasem',
 'hasor',
 'haste',
 'hasum',
 'hatil',
 'hatim',
 'hatus',
 'hauca',
 'haura',
 'haure',
 'hauri',
 'havai',
 'havei',
 'haver',
 'havia',
 'hazar',
 'hazel',
 'hazim',
 'hazor',
 'heber',
 'hecto',
 'hedra',
 'hefer',
 'hegai',
 'hegar',
 'hegel',
 'heide',
 'heidi',
 'helba',
 'helem',
 'helen',
 'helez',
 'helga',
 'helio',
 'helix',
 'helma',
 'helom',
 'hemal',
 'hemor',
 'henda',
 'henri',
 'henry',
 'hepta',
 'heras',
 'herda',
 'herde',
 'herdo',
 'heres',
 'hereu',
 'heril',
 'herma',
 'heroi',
 'heros',
 'herta',
 'hertz',
 'hesse',
 'heteu',
 'hevea',
 'heveu',
 'hexil',
 'hezir',
 'hiato',
 'hicso',
 'hidai',
 'hideo',
 'hidra',
 'hidro',
 'hiena',
 'hiero',
 'hifal',
 'hifen',
 'higgs',
 'hilal',
 'hilar',
 'hilde',
 'hildo',
 'hilel',
 'hilem',
 'hileu',
 'hilmi',
 'himen',
 'hindi',
 'hindu',
 'hinir',
 'hinom',
 'hinos',
 'hiper',
 'hipno',
 'hiram',
 'hirao',
 'histo',
 'hoare',
 'hobby',
 'hodes',
 'hodsi',
 'hofni',
 'hofra',
 'hogla',
 'holom',
 'homao',
 'homem',
 'homeo',
 'homer',
 'homum',
 'honda',
 'hongo',
 'honos',
 'honra',
 'honre',
 'honro',
 'horal',
 'horao',
 'horar',
 'horas',
 'horda',
 'horem',
 'horeu',
 'horma',
 'horom',
 'horta',
 'horto',
 'hosea',
 'hoste',
 'hotao',
 'hotel',
 'hotir',
 'houri',
 'houve',
 'huari',
 'huido',
 'hulda',
 'hulha',
 'humam',
 'humbe',
 'humbo',
 'humor',
 'humus',
 'hungo',
 'hunos',
 'hunta',
 'hupim',
 'hurai',
 'hurao',
 'hurra',
 'husai',
 'husam',
 'husao',
 'husim',
 'husna',
 'iacri',
 'iaias',
 'iambo',
 'iamos',
 'ianam',
 'iansa',
 'iaque',
 'iates',
 'ibama',
 'ibase',
 'ibate',
 'ibema',
 'ibere',
 'ibero',
 'ibiai',
 'ibiam',
 'ibira',
 'ibiri',
 'iblea',
 'iboga',
 'ibope',
 'ibsao',
 'ibsen',
 'icaco',
 'icada',
 'icado',
 'icais',
 'icamo',
 'icana',
 'icara',
 'icaro',
 'icatu',
 'icava',
 'iceis',
 'icemo',
 'icipo',
 'icolo',
 'icomo',
 'icone',
 'ictal',
 'ictus',
 'idade',
 'idala',
 'idate',
 'idbas',
 'ideal',
 'idear',
 'ideia',
 'idolo',
 'idosa',
 'idoso',
 'ielmo',
 'iemen',
 'ienes',
 'iento',
 'igaci',
 'igapo',
 'igara',
 'igeal',
 'igino',
 'ignea',
 'igneo',
 'iguai',
 'igual',
 'ijaci',
 'ijebu',
 'ijexa',
 'ijuis',
 'ilana',
 'ilena',
 'ileon',
 'ilesa',
 'ileso',
 'ilhai',
 'ilham',
 'ilhar',
 'ilhas',
 'ilhei',
 'ilhem',
 'ilhes',
 'ilheu',
 'ilhoa',
 'ilhou',
 'ilion',
 'ilium',
 'iliza',
 'ilona',
 'ilota',
 'ilson',
 'ilton',
 'iluda',
 'ilude',
 'iludi',
 'iludo',
 'iluso',
 'imago',
 'imame',
 'imbau',
 'imbus',
 'imido',
 'imita',
 'imite',
 'imito',
 'imola',
 'imole',
 'imolo',
 'impar',
 'impia',
 'impio',
 'impoe',
 'impor',
 'impos',
 'impus',
 'imune',
 'imuno',
 'inaja',
 'inala',
 'inale',
 'inalo',
 'iname',
 'inamu',
 'inara',
 'inari',
 'inata',
 'inato',
 'incar',
 'incas',
 'incha',
 'inche',
 'incho',
 'incoa',
 'incoe',
 'incoo',
 'index',
 'india',
 'indio',
 'induz',
 'inexo',
 'infer',
 'infla',
 'infle',
 'inflo',
 'infra',
 'ingas',
 'ingre',
 'ingua',
 'iniba',
 'inibe',
 'inibi',
 'inibo',
 'inion',
 'inite',
 'inova',
 'inove',
 'inovo',
 'insta',
 'inste',
 'insto',
 'insua',
 'intel',
 'inter',
 'intra',
 'intua',
 'intui',
 'intuo',
 'invar',
 'inves',
 'invio',
 'iodar',
 'iodos',
 'iogas',
 'iogue',
 'ionio',
 'ionte',
 'ipaba',
 'ipadu',
 'ipeca',
 'ipero',
 'ipiau',
 'ipira',
 'ipora',
 'ippur',
 'ipsis',
 'ipubi',
 'ipuca',
 'iques',
 'iraci',
 'irada',
 'irade',
 'irado',
 'iraja',
 'irani',
 'irati',
 'irdes',
 'irece',
 'ireis',
 'iremo',
 'irene',
 'irere',
 'irial',
 'iriam',
 'iriar',
 'irias',
 'irite',
 'irmao',
 'irmas',
 'irmos',
 'iroco',
 'iroso',
 'irucu',
 'irupi',
 'iscar',
 'iscas',
 'islao',
 'isnar',
 'isola',
 'isole',
 'isolo',
 'issei',
 'istmo',
 'istro',
 'itabi',
 'itaca',
 'itagi',
 'itaim',
 'itaja',
 'itaju',
 'itala',
 'italo',
 'itano',
 'itape',
 'itens',
 'itera',
 'itere',
 'itero',
 'itiel',
 'itrio',
 'ivana',
 'ivani',
 'ivate',
 'ivete',
 'ivone',
 'ivora',
 'ivoti',
 'ixora',
 'jaaca',
 'jaala',
 'jaate',
 'jabal',
 'jabes',
 'jabez',
 'jabim',
 'jabne',
 'jacas',
 'jacir',
 'jacui',
 'jacus',
 'jadai',
 'jades',
 'jadom',
 'jadua',
 'jaera',
 'jafar',
 'jafia',
 'jagur',
 'jaiba',
 'jaime',
 'jaina',
 'jairo',
 'jalao',
 'jalde',
 'jaldo',
 'jales',
 'jalom',
 'jamai',
 'jamal',
 'jambo',
 'jambu',
 'jamie',
 'jamil',
 'jamim',
 'jamir',
 'janai',
 'janes',
 'jango',
 'janim',
 'janio',
 'janir',
 'janoa',
 'janta',
 'jante',
 'janto',
 'japao',
 'japim',
 'jaque',
 'jarda',
 'jario',
 'jarir',
 'jaroa',
 'jarra',
 'jarro',
 'jasao',
 'jasen',
 'jasom',
 'jason',
 'jaspe',
 'jatai',
 'jatei',
 'jater',
 'jatir',
 'jatis',
 'jatos',
 'jaula',
 'jauru',
 'javae',
 'jazam',
 'jazas',
 'jazei',
 'jazem',
 'jazer',
 'jazes',
 'jazeu',
 'jazia',
 'jaziz',
 'jeans',
 'jebus',
 'jecas',
 'jefte',
 'jegue',
 'jeias',
 'jeiel',
 'jeira',
 'jeito',
 'jejes',
 'jejua',
 'jejue',
 'jejum',
 'jejuo',
 'jeoas',
 'jeque',
 'jequi',
 'jeser',
 'jesse',
 'jesua',
 'jesus',
 'jeter',
 'jetom',
 'jetro',
 'jetur',
 'jeuba',
 'jeude',
 'jeudi',
 'jeuel',
 'jezer',
 'jimba',
 'jimbo',
 'jinga',
 'jipao',
 'jiqui',
 'jirau',
 'jitai',
 'jitsu',
 'joabe',
 'joaci',
 'joada',
 'joana',
 'jobim',
 'jocal',
 'jocsa',
 'jocta',
 'jodie',
 'joede',
 'joela',
 'jofre',
 'jogai',
 'jogam',
 'jogao',
 'jogar',
 'jogas',
 'jogli',
 'jogos',
 'jogou',
 'jogue',
 'joias',
 'joice',
 'joios',
 'jomar',
 'jonas',
 'jongo',
 'jonio',
 'jooes',
 'jorai',
 'jorao',
 'jorge',
 'jorim',
 'jorra',
 'jorre',
 'jorro',
 'joses',
 'josie',
 'josue',
 'jotao',
 'jotas',
 'jotba',
 'joule',
 'jovem',
 'juana',
 'juara',
 'jubal',
 'jubas',
 'jucal',
 'jucas',
 'judas',
 'judeu',
 'judia',
 'judie',
 'judio',
 'jugal',
 'jugar',
 'jugos',
 'jugum',
 'juina',
 'juiza',
 'juizo',
 'julga',
 'julgo',
 'julho',
 'julia',
 'julio',
 'jumbo',
 'junca',
 'junco',
 'jundo',
 'jundu',
 'jungo',
 'junho',
 'junia',
 'junta',
 'junte',
 'junto',
 'jupia',
 'jurai',
 'juram',
 'jurao',
 'jurar',
 'juras',
 'jurei',
 'jurem',
 'jures',
 'juros',
 'jurou',
 'jurua',
 'justa',
 'justo',
 'jutai',
 'jutas',
 'juuna',
 'kafka',
 'karla',
 'katia',
 'kazuo',
 'kevin',
 'khmer',
 'kioto',
 'klaus',
 'klein',
 'kuito',
 'kwait',
 'laade',
 'labao',
 'labas',
 'labeo',
 'labeu',
 'labia',
 'labil',
 'labio',
 'labma',
 'labor',
 'labro',
 'lacai',
 'lacam',
 'lacao',
 'lacar',
 'lacas',
 'lacei',
 'lacem',
 'laces',
 'lacha',
 'lacio',
 'lacos',
 'lacou',
 'lacra',
 'lacre',
 'lacro',
 'lacum',
 'ladao',
 'lados',
 'ladra',
 'ladre',
 'ladro',
 'lagar',
 'lages',
 'lagoa',
 'lagos',
 'laica',
 'laico',
 'laika',
 'laila',
 'laisa',
 'lajem',
 'lajes',
 'lalau',
 'lalia',
 'lamar',
 'lamas',
 'lamba',
 'lambe',
 'lambi',
 'lambo',
 'lambu',
 'lamec',
 'lamim',
 'lampo',
 'lanar',
 'lanas',
 'lanca',
 'lance',
 'lanco',
 'lande',
 'landi',
 'lando',
 'lange',
 'langi',
 'lango',
 'lanho',
 'lanka',
 'lante',
 'lapao',
 'lapas',
 'lapis',
 'lapso',
 'laque',
 'lardo',
 'lares',
 'larga',
 'largo',
 'laria',
 'larva',
 'lasca',
 'lasco',
 'laser',
 'latao',
 'latas',
 'latem',
 'later',
 'lates',
 'latex',
 'latia',
 'latim',
 'latir',
 'latis',
 'latiu',
 'lauda',
 'laude',
 'laudo',
 'laura',
 'lauro',
 'lauto',
 'lavai',
 'lavam',
 'lavar',
 'lavas',
 'lavei',
 'lavem',
 'laves',
 'lavor',
 'lavou',
 'lavra',
 'lavre',
 'lavro',
 'laxar',
 'lazer',
 'leais',
 'lebeu',
 'lebon',
 'lebre',
 'ledes',
 'ledol',
 'ledor',
 'ledos',
 'legai',
 'legal',
 'legam',
 'legar',
 'legas',
 'legis',
 'legou',
 'legua',
 'legue',
 'leiam',
 'leias',
 'leide',
 'leiga',
 'leigo',
 'leila',
 'leite',
 'leito',
 'leiva',
 'leixa',
 'leixe',
 'lelio',
 'lelis',
 'lemas',
 'lemes',
 'lemos',
 'lemur',
 'lenca',
 'lenco',
 'lenda',
 'lende',
 'lendo',
 'lendu',
 'lenga',
 'lenha',
 'lenho',
 'lenin',
 'lenio',
 'lenir',
 'lenta',
 'lente',
 'lento',
 'leoas',
 'leoes',
 'leone',
 'leoni',
 'leote',
 'lepra',
 'lepto',
 'leque',
 'leram',
 'lerao',
 'leras',
 'lerca',
 'lerda',
 'lerdo',
 'lerei',
 'lerem',
 'leres',
 'leria',
 'lerio',
 'lermo',
 'lerna',
 'leroi',
 'lesai',
 'lesam',
 'lesao',
 'lesar',
 'lesas',
 'lesei',
 'lesem',
 'leses',
 'lesma',
 'lesos',
 'lesou',
 'lessa',
 'lesse',
 'leste',
 'letal',
 'letao',
 'letra',
 'leuco',
 'levai',
 'levam',
 'levar',
 'levas',
 'levei',
 'levem',
 'leves',
 'levou',
 'lexia',
 'lexis',
 'lhama',
 'lhano',
 'liame',
 'liana',
 'liane',
 'liase',
 'libar',
 'liber',
 'libia',
 'libio',
 'libna',
 'libni',
 'libra',
 'libre',
 'licao',
 'liceu',
 'licia',
 'licio',
 'licor',
 'licra',
 'lidai',
 'lidam',
 'lidar',
 'lidas',
 'lidei',
 'lidem',
 'lider',
 'lides',
 'lidia',
 'lidio',
 'lidos',
 'lidou',
 'lieis',
 'liene',
 'ligai',
 'ligal',
 'ligam',
 'ligar',
 'ligas',
 'ligou',
 'ligue',
 'lilas',
 'lilia',
 'limai',
 'limam',
 'limao',
 'limar',
 'limas',
 'limax',
 'limbo',
 'limei',
 'limem',
 'limes',
 'limos',
 'limou',
 'limpa',
 'limpe',
 'limpo',
 'lince',
 'linda',
 'linde',
 'lindo',
 'lineu',
 'linfa',
 'linfo',
 'linha',
 'linho',
 'linus',
 'linux',
 'lipes',
 'lipse',
 'liqui',
 'liras',
 'lirio',
 'lisas',
 'lisim',
 'lismo',
 'lisol',
 'lisos',
 'lisso',
 'lista',
 'liste',
 'listo',
 'litar',
 'litio',
 'litor',
 'litro',
 'livia',
 'livio',
 'livor',
 'livra',
 'livre',
 'livro',
 'lixai',
 'lixam',
 'lixao',
 'lixar',
 'lixas',
 'lixei',
 'lixem',
 'lixes',
 'lixos',
 'lixou',
 'lizar',
 'lobal',
 'lobao',
 'lobar',
 'lobas',
 'lobos',
 'locai',
 'local',
 'locam',
 'locao',
 'locar',
 'locas',
 'locou',
 'locus',
 'logar',
 'login',
 'logon',
 'logra',
 'logre',
 'logro',
 'logue',
 'loica',
 'loide',
 'loira',
 'loiro',
 'loisa',
 'lojas',
 'loman',
 'lomba',
 'lombi',
 'lombo',
 'lonas',
 'lonca',
 'londo',
 'longa',
 'longe',
 'longo',
 'lonja',
 'lopes',
 'loque',
 'lorca',
 'lorde',
 'lorio',
 'loros',
 'lorpa',
 'losna',
 'lotai',
 'lotam',
 'lotar',
 'lotas',
 'lotea',
 'lotei',
 'lotem',
 'lotes',
 'lotos',
 'lotou',
 'lotus',
 'louca',
 'louco',
 'loura',
 'louro',
 'lousa',
 'louva',
 'louve',
 'louvo',
 'lucao',
 'lucas',
 'lucia',
 'lucio',
 'lucra',
 'lucre',
 'lucro',
 'ludim',
 'lufar',
 'lugar',
 'lugol',
 'lugre',
 'luigi',
 'luisa',
 'luite',
 'luiza',
 'lulas',
 'lulus',
 'lumbo',
 'lumen',
 'lumes',
 'lunar',
 'lunda',
 'lundu',
 'lunfa',
 'lungo',
 'lupas',
 'lupia',
 'lupus',
 'luria',
 'lusas',
 'lusco',
 'lusia',
 'lusos',
 'lutai',
 'lutam',
 'lutar',
 'lutas',
 'lutei',
 'lutem',
 'luteo',
 'lutes',
 'lutos',
 'lutou',
 'lutra',
 'luvas',
 'luxar',
 'luxos',
 'luzam',
 'luzas',
 'luzem',
 'luzes',
 'luzia',
 'luzio',
 'luzir',
 'luzis',
 'luziu',
 'maaca',
 'maala',
 'maate',
 'mabea',
 'mabel',
 'macae',
 'macao',
 'macar',
 'macas',
 'macau',
 'macaz',
 'macha',
 'mache',
 'macho',
 'macia',
 'macio',
 'macis',
 'macla',
 'macom',
 'macos',
 'macro',
 'macua',
 'madai',
 'madia',
 'madom',
 'madre',
 'madri',
 'mafia',
 'mafra',
 'mafua',
 'magia',
 'magma',
 'magna',
 'magne',
 'magno',
 'magoa',
 'magoe',
 'magoo',
 'magos',
 'magra',
 'magro',
 'mahal',
 'mahdi',
 'maiar',
 'maias',
 'maica',
 'maida',
 'mails',
 'maina',
 'maior',
 'maios',
 'maira',
 'maire',
 'mairi',
 'mairu',
 'maisa',
 'maita',
 'maite',
 'major',
 'malar',
 'malas',
 'malba',
 'malca',
 'malco',
 'males',
 'malha',
 'malhe',
 'malho',
 'malom',
 'malta',
 'malva',
 'mamae',
 'mamai',
 'mamal',
 'mamam',
 'mamao',
 'mamar',
 'mamas',
 'mambo',
 'mamei',
 'mamem',
 'mames',
 'mamoa',
 'mamom',
 'mamou',
 'manal',
 'manar',
 'manas',
 'manau',
 'manca',
 'manco',
 'manda',
 'mande',
 'mandi',
 'mando',
 'mandu',
 'manea',
 'manes',
 'manga',
 'mango',
 'mangu',
 'manha',
 'manho',
 'mania',
 'manja',
 'manje',
 'manjo',
 'manju',
 'manoa',
 'manos',
 'manre',
 'mansa',
 'manso',
 'manta',
 'mante',
 'manto',
 'manua',
 'manue',
 'maome',
 'maori',
 'mapao',
 'mapas',
 'mapea',
 'maple',
 'maqui',
 'maraa',
 'marao',
 'marau',
 'marca',
 'marco',
 'mardo',
 'mares',
 'margo',
 'maria',
 'marie',
 'mario',
 'mariz',
 'marja',
 'marla',
 'marli',
 'marmo',
 'marra',
 'marta',
 'marte',
 'masai',
 'masal',
 'masca',
 'masco',
 'massa',
 'matai',
 'matam',
 'matao',
 'matar',
 'matas',
 'matat',
 'matei',
 'matem',
 'mater',
 'mates',
 'matiz',
 'matos',
 'matou',
 'matri',
 'mauba',
 'maula',
 'mauro',
 'maxim',
 'mayen',
 'mazia',
 'mciii',
 'meada',
 'meara',
 'meato',
 'mecam',
 'mecas',
 'mecha',
 'medao',
 'medea',
 'medem',
 'medes',
 'media',
 'medio',
 'medir',
 'medis',
 'mediu',
 'medos',
 'medra',
 'medre',
 'medro',
 'megan',
 'meiao',
 'meias',
 'meida',
 'meier',
 'meiga',
 'meigo',
 'meios',
 'meire',
 'meiru',
 'melai',
 'melam',
 'melao',
 'melar',
 'melas',
 'melba',
 'melea',
 'melei',
 'melem',
 'meleo',
 'meles',
 'melia',
 'meloa',
 'melou',
 'melro',
 'membe',
 'membi',
 'menas',
 'mende',
 'mendi',
 'mendo',
 'menea',
 'menes',
 'menga',
 'menha',
 'menia',
 'menir',
 'menor',
 'menos',
 'menso',
 'menta',
 'mente',
 'menti',
 'mento',
 'menus',
 'meola',
 'meras',
 'merca',
 'merce',
 'merda',
 'meres',
 'merim',
 'merom',
 'meros',
 'meroz',
 'mesao',
 'mesas',
 'meses',
 'mesio',
 'mesma',
 'mesmo',
 'mesna',
 'meson',
 'messa',
 'messe',
 'metal',
 'metam',
 'metas',
 'metei',
 'metem',
 'meter',
 'metes',
 'meteu',
 'metia',
 'metie',
 'metil',
 'metim',
 'metol',
 'metro',
 'meudo',
 'meuma',
 'mevio',
 'mexam',
 'mexas',
 'mexei',
 'mexem',
 'mexer',
 'mexes',
 'mexeu',
 'mexia',
 'mezzo',
 'miada',
 'miado',
 'miais',
 'miami',
 'miamo',
 'miana',
 'miara',
 'miava',
 'mibar',
 'mical',
 'micao',
 'micar',
 'micas',
 'miche',
 'micho',
 'micio',
 'micos',
 'micra',
 'micri',
 'micro',
 'midia',
 'midim',
 'mieis',
 'mielo',
 'miemo',
 'migas',
 'migra',
 'migre',
 'migro',
 'mijai',
 'mijam',
 'mijao',
 'mijar',
 'mijas',
 'mijei',
 'mijem',
 'mijes',
 'mijou',
 'milao',
 'milca',
 'milde',
 'mileu',
 'milha',
 'milho',
 'milio',
 'milpa',
 'mimai',
 'mimam',
 'mimar',
 'mimas',
 'mimei',
 'mimem',
 'mimes',
 'mimos',
 'mimou',
 'minai',
 'minam',
 'minar',
 'minas',
 'minaz',
 'minei',
 'minem',
 'mines',
 'mingo',
 'minha',
 'minho',
 'minio',
 'minis',
 'minke',
 'minor',
 'minou',
 'minta',
 'minto',
 'miojo',
 'miolo',
 'mioma',
 'miope',
 'miose',
 'mique',
 'mirai',
 'miram',
 'mirao',
 'mirar',
 'miras',
 'mirei',
 'mirem',
 'mires',
 'miria',
 'mirim',
 'mirma',
 'mirna',
 'mirou',
 'mirra',
 'mirto',
 'misal',
 'misia',
 'misio',
 'misma',
 'mispa',
 'missa',
 'misso',
 'mista',
 'miste',
 'misto',
 'mitca',
 'mitis',
 'mitos',
 'mitra',
 'miuda',
 'miude',
 'miudo',
 'mixar',
 'mixia',
 'mixos',
 'mixto',
 'mizpa',
 'moabe',
 'moafa',
 'moais',
 'moamo',
 'mobil',
 'mocao',
 'mocas',
 'mocha',
 'mochi',
 'mocho',
 'mocos',
 'mocsa',
 'modal',
 'modas',
 'modem',
 'modos',
 'modus',
 'moeda',
 'moeis',
 'moela',
 'moema',
 'moemo',
 'moera',
 'mofai',
 'mofam',
 'mofar',
 'mofas',
 'mofei',
 'mofem',
 'mofes',
 'mofos',
 'mofou',
 'mogem',
 'moges',
 'mogno',
 'mogol',
 'mogor',
 'moiam',
 'moias',
 'moida',
 'moido',
 'moira',
 'moire',
 'moiro',
 'moita',
 'mojar',
 'mojui',
 'molal',
 'molar',
 'molas',
 'molda',
 'molde',
 'moldo',
 'moles',
 'molha',
 'molhe',
 'molho',
 'mombe',
 'momos',
 'monas',
 'monco',
 'monde',
 'monge',
 'mongi',
 'mongo',
 'monhe',
 'moniz',
 'monja',
 'monos',
 'monta',
 'monte',
 'monto',
 'monza',
 'mooca',
 'morai',
 'moral',
 'moram',
 'morao',
 'morar',
 'moras',
 'morbo',
 'morda',
 'morde',
 'mordi',
 'mordo',
 'morei',
 'morel',
 'morem',
 'mores',
 'morfe',
 'morfo',
 'moria',
 'morim',
 'mormo',
 'morna',
 'morno',
 'moros',
 'morou',
 'morra',
 'morre',
 'morri',
 'morro',
 'morsa',
 'morse',
 'morso',
 'morta',
 'morte',
 'morto',
 'mosca',
 'moses',
 'mossa',
 'mossi',
 'mosto',
 'motar',
 'motel',
 'motim',
 'motor',
 'motos',
 'motum',
 'mouco',
 'moule',
 'moura',
 'mouro',
 'mouse',
 'movam',
 'movas',
 'movei',
 'movel',
 'movem',
 'mover',
 'moves',
 'moveu',
 'movia',
 'mozar',
 'muana',
 'mucio',
 'mucor',
 'mucos',
 'mucro',
 'mucum',
 'mudai',
 'mudam',
 'mudar',
 'mudas',
 'mudei',
 'mudem',
 'mudes',
 'mudez',
 'mudos',
 'mudou',
 'mudra',
 'muere',
 'muflo',
 'mufti',
 'mugem',
 'mugia',
 'mugir',
 'mugis',
 'mugiu',
 'mugre',
 'muita',
 'muito',
 'mujam',
 'mujas',
 'mulas',
 'multa',
 'multe',
 'multi',
 'multo',
 'mumia',
 'mundi',
 'mundo',
 'munem',
 'munes',
 'mungo',
 'munho',
 'munia',
 'munir',
 'munis',
 'muniu',
 'muniz',
 'munto',
 'munus',
 'mupim',
 'muque',
 'muqui',
 'murai',
 'mural',
 'muram',
 'murar',
 'muras',
 'murca',
 'murei',
 'murem',
 'mures',
 'murim',
 'muros',
 'murou',
 'murro',
 'murta',
 'murto',
 'musal',
 'musas',
 'musca',
 'musco',
 'museu',
 'musgo',
 'musme',
 'musse',
 'mussi',
 'mutua',
 'mutum',
 'mutuo',
 'naama',
 'naara',
 'naate',
 'nabal',
 'nabla',
 'nabor',
 'nabos',
 'nacao',
 'nacar',
 'nache',
 'nacip',
 'nacom',
 'nacor',
 'nacos',
 'nacre',
 'nadai',
 'nadam',
 'nadar',
 'nadas',
 'nadei',
 'nadem',
 'nader',
 'nades',
 'nadia',
 'nadir',
 'nadja',
 'nados',
 'nadou',
 'nafis',
 'nafta',
 'nagai',
 'nagar',
 'nagas',
 'nager',
 'nagos',
 'nagua',
 'nagui',
 'naida',
 'naife',
 'naipe',
 'naire',
 'naive',
 'naldo',
 'nambi',
 'nambu',
 'namir',
 'nanai',
 'nanam',
 'nanar',
 'nanas',
 'nanci',
 'nanda',
 'nandi',
 'nando',
 'nandu',
 'nanei',
 'nanem',
 'nanes',
 'nanga',
 'nanja',
 'nanou',
 'naoto',
 'naque',
 'narco',
 'nardo',
 'nargo',
 'nario',
 'nariz',
 'narra',
 'narre',
 'narro',
 'narte',
 'narva',
 'nasal',
 'nasca',
 'nasce',
 'nasci',
 'nasco',
 'nasim',
 'nasio',
 'nasir',
 'nasse',
 'natal',
 'natan',
 'natas',
 'natel',
 'natio',
 'natos',
 'nauru',
 'nauta',
 'naval',
 'naves',
 'navio',
 'nebai',
 'necro',
 'nedio',
 'nedum',
 'nefro',
 'negai',
 'negam',
 'negao',
 'negar',
 'negas',
 'negou',
 'negra',
 'negro',
 'negue',
 'negus',
 'neide',
 'neiel',
 'neiva',
 'nelas',
 'neles',
 'nelio',
 'nenem',
 'nenes',
 'nente',
 'nepal',
 'nepos',
 'neque',
 'neral',
 'nereo',
 'neres',
 'nereu',
 'nerio',
 'nerol',
 'nervo',
 'nesga',
 'nessa',
 'nesse',
 'nesta',
 'neste',
 'nesto',
 'netas',
 'netos',
 'neuma',
 'neuro',
 'neusa',
 'neuza',
 'nevai',
 'nevam',
 'nevao',
 'nevar',
 'nevas',
 'nevei',
 'nevem',
 'neves',
 'nevio',
 'nevis',
 'nevoa',
 'nevou',
 'nexos',
 'nezia',
 'ngana',
 'ngola',
 'nhaca',
 'nibaz',
 'nibsa',
 'nicar',
 'nicas',
 'nicho',
 'nicia',
 'nidar',
 'nidia',
 'nigel',
 'niger',
 'nigra',
 'nilma',
 'nilva',
 'nimas',
 'nimbo',
 'nimio',
 'ninai',
 'ninam',
 'ninar',
 'ninas',
 'ninei',
 'ninem',
 'nines',
 'ninfa',
 'ninga',
 'ninho',
 'ninja',
 'ninou',
 'ninra',
 'ninsi',
 'niple',
 'nipoa',
 'nisao',
 'nisei',
 'nisia',
 'nisio',
 'nispo',
 'nisso',
 'nisto',
 'nital',
 'nitro',
 'nival',
 'nivel',
 'niveo',
 'nivia',
 'nobel',
 'nobil',
 'nobis',
 'noble',
 'nobre',
 'nocao',
 'nocio',
 'nodal',
 'nodoa',
 'nodos',
 'noeis',
 'noema',
 'noemi',
 'noese',
 'nogai',
 'nogal',
 'noise',
 'noite',
 'noiva',
 'noive',
 'noivo',
 'nojos',
 'nolan',
 'nomea',
 'nomes',
 'nomia',
 'nonde',
 'nondo',
 'nones',
 'nonio',
 'nonos',
 'nopal',
 'noras',
 'norio',
 'norma',
 'norte',
 'nossa',
 'nosso',
 'notai',
 'notal',
 'notam',
 'notar',
 'notas',
 'notei',
 'notem',
 'notes',
 'notou',
 'notre',
 'noute',
 'novar',
 'novas',
 'novel',
 'novoa',
 'novos',
 'noxal',
 'noxio',
 'nozes',
 'nubil',
 'nubio',
 'nucal',
 'nudez',
 'nueza',
 'nujol',
 'nulas',
 'nulos',
 'numas',
 'numes',
 'nunca',
 'nunes',
 'nuper',
 'nuria',
 'nutar',
 'nutra',
 'nutre',
 'nutri',
 'nutro',
 'nutum',
 'nuvem',
 'nylon',
 'oasis',
 'obede',
 'obesa',
 'obeso',
 'obice',
 'obito',
 'oboaz',
 'obolo',
 'obote',
 'obrar',
 'obras',
 'obsta',
 'obste',
 'obsto',
 'obtem',
 'obter',
 'obulo',
 'obvia',
 'obvie',
 'obvio',
 'ocano',
 'ocapi',
 'ocaso',
 'ocelo',
 'ocrea',
 'octal',
 'octil',
 'oculo',
 'ocupa',
 'ocupe',
 'ocupo',
 'odair',
 'odara',
 'odede',
 'odeia',
 'odeie',
 'odeio',
 'odele',
 'odeon',
 'odete',
 'odiai',
 'odiar',
 'odico',
 'odiei',
 'odilo',
 'odios',
 'odiou',
 'odite',
 'odito',
 'oeste',
 'ofaie',
 'ofato',
 'ofito',
 'ofuro',
 'ogano',
 'ogiva',
 'ogoni',
 'oiara',
 'oicam',
 'oidio',
 'oiros',
 'oitao',
 'oitis',
 'olaia',
 'olala',
 'olavo',
 'olear',
 'oleos',
 'olhai',
 'olhal',
 'olham',
 'olhao',
 'olhar',
 'olhas',
 'olhei',
 'olhem',
 'olhes',
 'olhos',
 'olhou',
 'oligo',
 'oliva',
 'olmos',
 'omaha',
 'omala',
 'omalo',
 'omani',
 'omano',
 'omaso',
 'ombro',
 'omega',
 'omele',
 'omita',
 'omite',
 'omiti',
 'omito',
 'omnes',
 'omolu',
 'omulu',
 'oncas',
 'ondar',
 'ondas',
 'ondea',
 'onera',
 'onere',
 'onero',
 'onias',
 'onico',
 'ontem',
 'opaca',
 'opaco',
 'opaie',
 'opala',
 'opalo',
 'opcao',
 'opera',
 'opere',
 'opero',
 'opiar',
 'opina',
 'opine',
 'opino',
 'opoem',
 'opoes',
 'opomo',
 'opora',
 'optai',
 'optam',
 'optar',
 'optas',
 'optei',
 'optem',
 'optes',
 'optou',
 'orado',
 'orago',
 'orais',
 'oramo',
 'orara',
 'orate',
 'orava',
 'orcai',
 'orcam',
 'orcar',
 'orcas',
 'orcei',
 'orcem',
 'orces',
 'orcou',
 'ordem',
 'orebe',
 'oreis',
 'oremo',
 'orfao',
 'orfas',
 'orfeu',
 'orgao',
 'orgem',
 'orges',
 'orgia',
 'orica',
 'orico',
 'oriel',
 'orion',
 'orixa',
 'oriza',
 'orlai',
 'orlam',
 'orlar',
 'orlas',
 'orlei',
 'orlem',
 'orles',
 'orlou',
 'ornai',
 'ornam',
 'ornar',
 'ornas',
 'ornei',
 'ornem',
 'ornes',
 'ornis',
 'ornou',
 'orobo',
 'oroco',
 'oromo',
 'oropa',
 'orque',
 'orqui',
 'orson',
 'ortiz',
 'osaca',
 'oscar',
 'oseas',
 'osias',
 'osmar',
 'osmio',
 'osmol',
 'osram',
 'ossae',
 'ossea',
 'osseo',
 'ossos',
 'osteo',
 'ostia',
 'ostio',
 'ostra',
 'ostro',
 'otava',
 'oteca',
 'otelo',
 'otica',
 'otico',
 'otima',
 'otimo',
 'otina',
 'otite',
 'otomi',
 'otona',
 'otoni',
 'oucam',
 'oucas',
 'ouros',
 'ousai',
 'ousam',
 'ousar',
 'ousas',
 'ousei',
 'ousem',
 'ouses',
 'ousou',
 'outao',
 'outra',
 'outro',
 'ouvem',
 'ouves',
 'ouvia',
 'ouvir',
 'ouvis',
 'ouviu',
 'ovais',
 'ovalo',
 'ovate',
 'ovino',
 'ovulo',
 'oxala',
 'oxida',
 'oxide',
 'oxido',
 'oxima',
 'oxina',
 'ozena',
 'ozias',
 'oziel',
 'pablo',
 'pacao',
 'pacas',
 'pacau',
 'pacho',
 'pacos',
 'pacto',
 'pacui',
 'pacus',
 'padom',
 'padre',
 'paete',
 'pafos',
 'pagai',
 'pagam',
 'pagao',
 'pagar',
 'pagas',
 'pagem',
 'pager',
 'pagos',
 'pagou',
 'pagua',
 'pague',
 'paial',
 'paica',
 'paina',
 'paiol',
 'paira',
 'paire',
 'pairo',
 'paixa',
 'pajau',
 'pajea',
 'pajem',
 'pajes',
 'pajeu',
 'palal',
 'palar',
 'palas',
 'palau',
 'palavra',
 'palco',
 'palea',
 'paleo',
 'palha',
 'palio',
 'palma',
 'palmo',
 'palor',
 'palos',
 'palti',
 'pampa',
 'pampo',
 'panal',
 'panao',
 'panca',
 'panda',
 'panga',
 'panhe',
 'panos',
 'panta',
 'paolo',
 'papai',
 'papal',
 'papam',
 'papao',
 'papar',
 'papas',
 'papei',
 'papel',
 'papem',
 'papes',
 'papos',
 'papou',
 'papua',
 'paque',
 'paqui',
 'parai',
 'paral',
 'param',
 'parao',
 'parar',
 'paras',
 'parca',
 'parco',
 'parda',
 'pardo',
 'parea',
 'parei',
 'parem',
 'pareo',
 'pares',
 'pargo',
 'paria',
 'pario',
 'parir',
 'paris',
 'pariu',
 'parma',
 'parol',
 'paros',
 'parou',
 'parse',
 'parsi',
 'parta',
 'parte',
 'parti',
 'parto',
 'parua',
 'parum',
 'parus',
 'parvi',
 'parvo',
 'pasea',
 'pasma',
 'pasme',
 'pasmo',
 'passa',
 'passe',
 'passo',
 'pasta',
 'paste',
 'pasto',
 'pasur',
 'patas',
 'pataz',
 'patel',
 'pateo',
 'pater',
 'patia',
 'patim',
 'patio',
 'patis',
 'patoa',
 'patos',
 'patua',
 'pauis',
 'paula',
 'paulo',
 'paura',
 'pausa',
 'pauta',
 'paute',
 'pauto',
 'pauxi',
 'pavao',
 'paves',
 'pavio',
 'pavoa',
 'pavor',
 'pazes',
 'peana',
 'peano',
 'pebas',
 'pecai',
 'pecam',
 'pecar',
 'pecas',
 'pecou',
 'pedal',
 'pedem',
 'pedes',
 'pedia',
 'pedir',
 'pedis',
 'pediu',
 'pedra',
 'pedro',
 'pegai',
 'pegam',
 'pegar',
 'pegas',
 'pegma',
 'pegos',
 'pegou',
 'pegue',
 'peida',
 'peide',
 'peido',
 'peita',
 'peite',
 'peito',
 'peixe',
 'pejar',
 'pejos',
 'pelai',
 'pelam',
 'pelao',
 'pelar',
 'pelas',
 'pelei',
 'pelem',
 'peles',
 'pelia',
 'pelor',
 'pelos',
 'pelou',
 'pelta',
 'pelve',
 'pemom',
 'penai',
 'penal',
 'penam',
 'penao',
 'penar',
 'penas',
 'penca',
 'pence',
 'penda',
 'pende',
 'pendi',
 'pendo',
 'penei',
 'penem',
 'penes',
 'penha',
 'penia',
 'penis',
 'penol',
 'penou',
 'pensa',
 'pense',
 'penso',
 'penta',
 'pente',
 'peoes',
 'peona',
 'pepel',
 'pepes',
 'peplo',
 'peque',
 'pequi',
 'peral',
 'peras',
 'perau',
 'perca',
 'perce',
 'perco',
 'perda',
 'perde',
 'perdi',
 'peres',
 'perez',
 'perge',
 'perna',
 'perno',
 'peroa',
 'peros',
 'perro',
 'persa',
 'perto',
 'perua',
 'perus',
 'pesai',
 'pesam',
 'pesao',
 'pesar',
 'pesas',
 'pesca',
 'pesco',
 'pesei',
 'pesem',
 'peses',
 'pesos',
 'pesou',
 'pesse',
 'peste',
 'petal',
 'petar',
 'petas',
 'petim',
 'petit',
 'petiz',
 'petor',
 'petos',
 'petra',
 'petri',
 'petro',
 'petum',
 'peuva',
 'pexia',
 'pexim',
 'pezao',
 'piaba',
 'piada',
 'piado',
 'piaga',
 'piais',
 'piamo',
 'piano',
 'piape',
 'piara',
 'piata',
 'piaui',
 'piaus',
 'piava',
 'picai',
 'pical',
 'picam',
 'picao',
 'picar',
 'picas',
 'picha',
 'piche',
 'pichi',
 'picho',
 'picos',
 'picou',
 'picro',
 'picto',
 'picua',
 'picui',
 'picum',
 'pidao',
 'pieis',
 'piemo',
 'piero',
 'pieta',
 'piezo',
 'pifai',
 'pifam',
 'pifao',
 'pifar',
 'pifas',
 'pifei',
 'pifem',
 'pifes',
 'pifio',
 'pifou',
 'pigro',
 'piina',
 'pilao',
 'pilar',
 'pilau',
 'pileo',
 'pilha',
 'pilhe',
 'pilho',
 'pinar',
 'pinca',
 'pince',
 'pinco',
 'pinda',
 'pindo',
 'pinel',
 'pineu',
 'pinga',
 'pingo',
 'pinha',
 'pinhe',
 'pinho',
 'pinom',
 'pinos',
 'pinta',
 'pinte',
 'pinto',
 'pioca',
 'pioes',
 'piola',
 'pioli',
 'piora',
 'piore',
 'pioro',
 'pipar',
 'pipas',
 'pipra',
 'pique',
 'piqui',
 'piral',
 'pirao',
 'pirar',
 'pires',
 'pirex',
 'piria',
 'pirol',
 'pirro',
 'pirua',
 'pisai',
 'pisam',
 'pisao',
 'pisar',
 'pisas',
 'pisca',
 'pisco',
 'pisei',
 'pisem',
 'pises',
 'pisga',
 'pisom',
 'pisos',
 'pisou',
 'pispa',
 'pissa',
 'pista',
 'pitao',
 'pitar',
 'pitas',
 'piteu',
 'pitia',
 'pitio',
 'pitiu',
 'pitom',
 'piton',
 'pitos',
 'pitot',
 'pitui',
 'piuba',
 'piuma',
 'piuna',
 'piuva',
 'pivos',
 'pixel',
 'pizza',
 'placa',
 'place',
 'plaga',
 'plana',
 'plane',
 'plani',
 'plano',
 'plato',
 'plebe',
 'plena',
 'pleno',
 'pleon',
 'plexo',
 'plome',
 'plomo',
 'plota',
 'plote',
 'ploto',
 'pluga',
 'plugo',
 'pluma',
 'plumo',
 'pluri',
 'plush',
 'pluto',
 'pneus',
 'poaia',
 'pobre',
 'pocao',
 'pocas',
 'poche',
 'pocos',
 'podai',
 'podal',
 'podam',
 'podao',
 'podar',
 'podas',
 'podei',
 'podem',
 'poder',
 'podes',
 'podia',
 'podio',
 'podou',
 'podre',
 'poejo',
 'poema',
 'poeta',
 'poial',
 'poios',
 'poisa',
 'poita',
 'polar',
 'polca',
 'poldo',
 'polen',
 'polex',
 'polia',
 'polim',
 'polio',
 'polir',
 'polis',
 'poliu',
 'polje',
 'polme',
 'polos',
 'polpa',
 'polux',
 'polvo',
 'pomar',
 'pomas',
 'pomba',
 'pombe',
 'pombo',
 'pomes',
 'pomos',
 'pompa',
 'ponca',
 'ponda',
 'ponde',
 'pondo',
 'ponei',
 'ponha',
 'ponho',
 'ponje',
 'ponta',
 'ponte',
 'ponto',
 'popao',
 'porao',
 'poras',
 'porca',
 'porco',
 'porei',
 'porem',
 'pores',
 'poria',
 'porio',
 'porme',
 'porno',
 'poros',
 'porra',
 'porre',
 'porro',
 'porta',
 'porte',
 'porto',
 'posai',
 'posam',
 'posar',
 'posas',
 'posei',
 'posem',
 'poses',
 'posou',
 'pospo',
 'possa',
 'posse',
 'posso',
 'posta',
 'poste',
 'posto',
 'potes',
 'potim',
 'potos',
 'potro',
 'potum',
 'pouca',
 'pouco',
 'poule',
 'poupa',
 'poupe',
 'poupo',
 'pousa',
 'pouse',
 'pouso',
 'povao',
 'povoa',
 'povoe',
 'povoo',
 'povos',
 'poxim',
 'praca',
 'prado',
 'praga',
 'praia',
 'prais',
 'prana',
 'prase',
 'prata',
 'prato',
 'praxe',
 'prazo',
 'prear',
 'preas',
 'prece',
 'preco',
 'prega',
 'prego',
 'preia',
 'prelo',
 'prema',
 'preme',
 'premi',
 'premo',
 'preon',
 'prepo',
 'presa',
 'preso',
 'preta',
 'preto',
 'preve',
 'previ',
 'preza',
 'preze',
 'prezo',
 'prima',
 'prime',
 'primi',
 'primo',
 'prion',
 'prior',
 'prita',
 'prius',
 'priva',
 'prive',
 'privo',
 'proas',
 'probo',
 'proca',
 'proco',
 'prole',
 'propo',
 'prosa',
 'proto',
 'prova',
 'prove',
 'provi',
 'provo',
 'prumo',
 'psico',
 'ptena',
 'ptose',
 'puaia',
 'pubis',
 'pucas',
 'puder',
 'pudim',
 'pudor',
 'pueri',
 'pulai',
 'pulam',
 'pulao',
 'pular',
 'pulas',
 'pulei',
 'pulem',
 'pules',
 'pulga',
 'pulha',
 'pulos',
 'pulou',
 'pulsa',
 'pulse',
 'pulso',
 'pumas',
 'punam',
 'punas',
 'punem',
 'punes',
 'punga',
 'punha',
 'punho',
 'punia',
 'punir',
 'punis',
 'puniu',
 'punks',
 'punom',
 'pupal',
 'pupar',
 'puras',
 'purga',
 'purgo',
 'purim',
 'puros',
 'purui',
 'purus',
 'puser',
 'putal',
 'putas',
 'putos',
 'puxai',
 'puxam',
 'puxao',
 'puxar',
 'puxas',
 'puxei',
 'puxem',
 'puxes',
 'puxos',
 'puxou',
 'qaeda',
 'quado',
 'quaga',
 'quais',
 'quale',
 'quali',
 'quari',
 'quark',
 'quasa',
 'quase',
 'quati',
 'queca',
 'queda',
 'quede',
 'quedo',
 'queia',
 'queno',
 'quepe',
 'quepi',
 'quera',
 'quere',
 'queri',
 'quero',
 'quete',
 'queto',
 'queza',
 'quibe',
 'quica',
 'quice',
 'quico',
 'quido',
 'quili',
 'quilo',
 'quima',
 'quimi',
 'quimo',
 'quina',
 'quino',
 'quios',
 'quipa',
 'quire',
 'quiri',
 'quiro',
 'quita',
 'quite',
 'quito',
 'quiui',
 'quivi',
 'quixo',
 'quota',
 'raabe',
 'raama',
 'rabao',
 'rabel',
 'rabia',
 'rabil',
 'rabos',
 'racal',
 'racao',
 'racas',
 'racha',
 'rache',
 'racho',
 'racio',
 'racom',
 'radai',
 'radao',
 'radar',
 'radia',
 'radie',
 'radio',
 'radom',
 'radon',
 'rafia',
 'ragau',
 'rague',
 'raiai',
 'raial',
 'raiam',
 'raiao',
 'raiar',
 'raias',
 'raide',
 'raiei',
 'raiem',
 'raies',
 'raiom',
 'raios',
 'raiou',
 'raisa',
 'raiva',
 'rajai',
 'rajam',
 'rajao',
 'rajar',
 'rajas',
 'rajei',
 'rajem',
 'rajes',
 'rajou',
 'ralai',
 'ralam',
 'ralao',
 'ralar',
 'ralas',
 'ralei',
 'ralem',
 'rales',
 'ralha',
 'ralhe',
 'ralho',
 'ralos',
 'ralou',
 'ramal',
 'ramao',
 'ramar',
 'ramas',
 'rambo',
 'ramie',
 'ramon',
 'ramos',
 'rampa',
 'ranco',
 'range',
 'rangi',
 'ranho',
 'ranja',
 'ranjo',
 'rapai',
 'rapam',
 'rapar',
 'rapas',
 'rapaz',
 'rapei',
 'rapel',
 'rapem',
 'rapes',
 'rapou',
 'rapta',
 'rapte',
 'rapto',
 'raque',
 'raras',
 'rarea',
 'raros',
 'rasao',
 'rasas',
 'rasca',
 'rasga',
 'rasgo',
 'rasos',
 'raspa',
 'raspe',
 'raspo',
 'raste',
 'rasto',
 'ratao',
 'ratar',
 'ratas',
 'ratea',
 'ratel',
 'ratos',
 'razao',
 'reage',
 'reagi',
 'reais',
 'reaja',
 'reajo',
 'reale',
 'reata',
 'reate',
 'reato',
 'reave',
 'rebar',
 'reboa',
 'reboe',
 'reboo',
 'rebus',
 'recai',
 'recao',
 'recar',
 'recea',
 'recem',
 'recos',
 'recto',
 'recua',
 'recue',
 'recuo',
 'redea',
 'redes',
 'redia',
 'redil',
 'redis',
 'redor',
 'redox',
 'reduz',
 'reege',
 'refaz',
 'refem',
 'refez',
 'refil',
 'refiz',
 'regai',
 'regal',
 'regam',
 'regao',
 'regar',
 'regas',
 'regei',
 'regem',
 'reger',
 'reges',
 'regeu',
 'regia',
 'regie',
 'regio',
 'regis',
 'regos',
 'regou',
 'regra',
 'regre',
 'regro',
 'regua',
 'regue',
 'reich',
 'reide',
 'reima',
 'reina',
 'reine',
 'reino',
 'rejam',
 'rejas',
 'relar',
 'reler',
 'reles',
 'releu',
 'relha',
 'relho',
 'relia',
 'relva',
 'remai',
 'remam',
 'remar',
 'remas',
 'remei',
 'remem',
 'remes',
 'remia',
 'remir',
 'remis',
 'remiu',
 'remix',
 'remoa',
 'remoe',
 'remoi',
 'remoo',
 'remos',
 'remou',
 'renal',
 'renan',
 'renas',
 'renda',
 'rende',
 'rendi',
 'rendo',
 'renfa',
 'rengo',
 'renio',
 'rente',
 'renzo',
 'reobe',
 'repes',
 'repoe',
 'repor',
 'repos',
 'repto',
 'repus',
 'reque',
 'resen',
 'reses',
 'resma',
 'respe',
 'respo',
 'ressa',
 'resta',
 'reste',
 'resto',
 'retal',
 'retar',
 'retas',
 'retem',
 'reter',
 'retor',
 'retos',
 'retro',
 'reuel',
 'reuma',
 'reuna',
 'reune',
 'reuni',
 'reuno',
 'reusa',
 'reuso',
 'revel',
 'revem',
 'rever',
 'reves',
 'revia',
 'revir',
 'reviu',
 'rezai',
 'rezam',
 'rezao',
 'rezar',
 'rezas',
 'rezei',
 'rezem',
 'rezes',
 'rezim',
 'rezom',
 'rezou',
 'riais',
 'riamo',
 'riana',
 'riane',
 'riano',
 'riata',
 'ribai',
 'ribas',
 'ribla',
 'ricao',
 'ricas',
 'ricio',
 'ricos',
 'ricto',
 'rides',
 'rieis',
 'rifai',
 'rifam',
 'rifao',
 'rifar',
 'rifas',
 'rifei',
 'rifem',
 'rifes',
 'rifle',
 'rifou',
 'rifte',
 'rigar',
 'rigor',
 'rigos',
 'rijal',
 'rijas',
 'rijos',
 'rildo',
 'rilha',
 'rimai',
 'rimam',
 'rimar',
 'rimas',
 'rimei',
 'rimel',
 'rimem',
 'rimes',
 'rimom',
 'rimos',
 'rimou',
 'rinal',
 'rinco',
 'rindo',
 'ringo',
 'rinto',
 'ripar',
 'ripas',
 'rique',
 'riram',
 'rirao',
 'riras',
 'rirei',
 'rirem',
 'rires',
 'riria',
 'rirmo',
 'risao',
 'risca',
 'risco',
 'risor',
 'risos',
 'rispa',
 'rissa',
 'risse',
 'risso',
 'riste',
 'ritas',
 'ritma',
 'ritmo',
 'ritos',
 'rival',
 'rixar',
 'rixas',
 'rizar',
 'rizia',
 'ro-ro',
 'roais',
 'roamo',
 'roana',
 'robia',
 'robin',
 'roble',
 'robos',
 'robot',
 'rocai',
 'rocam',
 'rocar',
 'rocas',
 'rocei',
 'rocem',
 'roces',
 'rocha',
 'rocim',
 'rocio',
 'rocou',
 'rodai',
 'rodam',
 'rodao',
 'rodar',
 'rodas',
 'rodea',
 'rodei',
 'rodel',
 'rodem',
 'rodes',
 'rodio',
 'rodos',
 'rodou',
 'roeis',
 'roemo',
 'roera',
 'rogai',
 'rogam',
 'rogar',
 'rogas',
 'rogel',
 'rogem',
 'roger',
 'roges',
 'rogos',
 'rogou',
 'rogue',
 'roiam',
 'roias',
 'roida',
 'roido',
 'rojao',
 'rolai',
 'rolam',
 'rolao',
 'rolar',
 'rolas',
 'rolei',
 'rolem',
 'roles',
 'rolha',
 'rolho',
 'rolim',
 'rolos',
 'rolou',
 'romar',
 'romas',
 'rombo',
 'romeu',
 'rompa',
 'rompe',
 'rompi',
 'rompo',
 'ronan',
 'ronca',
 'ronco',
 'ronda',
 'ronde',
 'rondo',
 'ronei',
 'ronga',
 'ronge',
 'ronha',
 'roque',
 'rosal',
 'rosao',
 'rosar',
 'rosas',
 'rosca',
 'rosea',
 'roseo',
 'rosna',
 'rosne',
 'rosno',
 'rosto',
 'rotar',
 'rotas',
 'rotea',
 'rotim',
 'rotor',
 'rotos',
 'rouba',
 'roube',
 'roubo',
 'rouca',
 'rouco',
 'rouen',
 'rouge',
 'round',
 'roupa',
 'roxas',
 'roxos',
 'ruama',
 'ruano',
 'rubem',
 'ruben',
 'rubim',
 'rubis',
 'rublo',
 'rubor',
 'rubra',
 'rubro',
 'rucos',
 'rudes',
 'rudez',
 'ruela',
 'rufai',
 'rufam',
 'rufar',
 'rufas',
 'rufei',
 'rufem',
 'rufes',
 'rufos',
 'rufou',
 'rugai',
 'rugam',
 'rugar',
 'rugas',
 'rugbi',
 'ruges',
 'rugia',
 'rugir',
 'rugis',
 'rugiu',
 'rugou',
 'rugue',
 'ruiam',
 'ruias',
 'ruido',
 'ruimo',
 'ruina',
 'ruins',
 'ruira',
 'ruiva',
 'ruivo',
 'rujam',
 'rujas',
 'rumai',
 'rumam',
 'rumar',
 'rumas',
 'rumei',
 'rumem',
 'rumen',
 'rumes',
 'rumor',
 'rumos',
 'rumou',
 'rumpi',
 'rundo',
 'rupia',
 'rural',
 'rusga',
 'russa',
 'russo',
 'rutas',
 'rutul',
 'saafe',
 'saara',
 'sabal',
 'sabao',
 'sabei',
 'sabem',
 'saber',
 'sabes',
 'sabeu',
 'sabia',
 'sabin',
 'sabio',
 'sabir',
 'sable',
 'sabor',
 'sabra',
 'sabre',
 'sabta',
 'sacai',
 'sacal',
 'sacam',
 'sacar',
 'sacas',
 'sacha',
 'sache',
 'sacho',
 'sacia',
 'sacie',
 'sacio',
 'sacis',
 'sacos',
 'sacou',
 'sacra',
 'sacre',
 'sacro',
 'sadia',
 'sadim',
 'sadio',
 'sadoc',
 'saeta',
 'safai',
 'safam',
 'safar',
 'safas',
 'safei',
 'safem',
 'safer',
 'safes',
 'safio',
 'safir',
 'safos',
 'safou',
 'safra',
 'sagas',
 'sagaz',
 'sagio',
 'sagra',
 'sagre',
 'sagro',
 'sagui',
 'sagum',
 'saiam',
 'saiao',
 'saias',
 'saiba',
 'saibo',
 'saica',
 'saida',
 'saido',
 'saimo',
 'saino',
 'saint',
 'saira',
 'saire',
 'saita',
 'salai',
 'salao',
 'salas',
 'salaz',
 'salca',
 'salda',
 'salde',
 'saldo',
 'salem',
 'sales',
 'salga',
 'salgo',
 'salim',
 'salma',
 'salmi',
 'salmo',
 'saloa',
 'salpa',
 'salsa',
 'salso',
 'salta',
 'salte',
 'salto',
 'salum',
 'salva',
 'salve',
 'salvo',
 'samai',
 'samba',
 'sambe',
 'sambo',
 'sameu',
 'samir',
 'samoa',
 'samua',
 'sanai',
 'sanam',
 'sanar',
 'sanas',
 'sandi',
 'sanea',
 'sanei',
 'sanem',
 'sanes',
 'sango',
 'sanha',
 'sanie',
 'sanou',
 'santa',
 'sante',
 'santo',
 'sapao',
 'sapar',
 'sapos',
 'sapro',
 'saque',
 'saqui',
 'sarah',
 'sarai',
 'saram',
 'saran',
 'sarao',
 'sarar',
 'saras',
 'sarau',
 'sarca',
 'sarco',
 'sarda',
 'sarei',
 'sarem',
 'sares',
 'sargo',
 'sarna',
 'sarom',
 'saros',
 'sarou',
 'sarro',
 'sarue',
 'sarug',
 'sasai',
 'sauas',
 'sauda',
 'saude',
 'saudi',
 'saudo',
 'sauim',
 'saulo',
 'sauna',
 'sauro',
 'sauva',
 'savel',
 'savia',
 'savio',
 'saxao',
 'saxeo',
 'saxes',
 'sayao',
 'sazao',
 'scene',
 'scope',
 'score',
 'seada',
 'seara',
 'seber',
 'sebes',
 'sebna',
 'sebos',
 'secad',
 'secai',
 'secam',
 'secao',
 'secar',
 'secas',
 'secia',
 'secio',
 'secos',
 'secou',
 'secto',
 'sedar',
 'sedas',
 'sedem',
 'sedes',
 'sedex',
 'sedia',
 'sedie',
 'sedio',
 'seduz',
 'seera',
 'sefer',
 'segao',
 'segar',
 'segne',
 'segno',
 'segue',
 'segui',
 'seios',
 'seira',
 'seisa',
 'seita',
 'seiva',
 'seixo',
 'sejam',
 'sejas',
 'selai',
 'selam',
 'selar',
 'selas',
 'selei',
 'selem',
 'seles',
 'selha',
 'selia',
 'selic',
 'selim',
 'selma',
 'selos',
 'selou',
 'selva',
 'semaa',
 'semea',
 'semei',
 'semen',
 'semer',
 'semio',
 'senaa',
 'senai',
 'senal',
 'senao',
 'senas',
 'senda',
 'sendo',
 'senes',
 'senha',
 'senho',
 'senil',
 'senir',
 'senna',
 'senos',
 'senso',
 'sensu',
 'senta',
 'sente',
 'senti',
 'sento',
 'senua',
 'sepia',
 'sepse',
 'seque',
 'seral',
 'serao',
 'seras',
 'serbo',
 'serei',
 'serem',
 'seres',
 'seria',
 'serie',
 'serio',
 'serna',
 'serpe',
 'serra',
 'serre',
 'serro',
 'serta',
 'serum',
 'serva',
 'serve',
 'servi',
 'servo',
 'serze',
 'serzi',
 'sesai',
 'sesgo',
 'sesta',
 'sesto',
 'setar',
 'setas',
 'setes',
 'setor',
 'setos',
 'setra',
 'setur',
 'sevar',
 'sexar',
 'sexos',
 'sexta',
 'sexto',
 'sezao',
 'shoyu',
 'sialo',
 'sibal',
 'sibma',
 'sicar',
 'siclo',
 'sidim',
 'sidom',
 'sidos',
 'sidra',
 'siena',
 'sifao',
 'sifra',
 'sifta',
 'sigam',
 'sigas',
 'sigla',
 'sigma',
 'signo',
 'silas',
 'silem',
 'silex',
 'silfo',
 'silim',
 'siloe',
 'silos',
 'silsa',
 'silte',
 'silva',
 'silve',
 'silvo',
 'simao',
 'simbo',
 'simei',
 'simil',
 'simio',
 'simon',
 'simum',
 'sinai',
 'sinal',
 'sinao',
 'sinar',
 'sinas',
 'sindi',
 'sindo',
 'singa',
 'sinha',
 'sinho',
 'sinim',
 'sinje',
 'sinos',
 'sinri',
 'sinta',
 'sinto',
 'sinus',
 'sipai',
 'sipia',
 'sique',
 'siria',
 'sirio',
 'siris',
 'siriu',
 'sirte',
 'sirva',
 'sirvo',
 'sirza',
 'sirzo',
 'sisal',
 'sisao',
 'sisar',
 'sisma',
 'sisme',
 'sismo',
 'sisos',
 'sisto',
 'sitar',
 'sitas',
 'sitia',
 'sitie',
 'sitim',
 'sitio',
 'sitna',
 'sitos',
 'sitri',
 'situa',
 'situe',
 'situo',
 'sivia',
 'skate',
 'slide',
 'snobe',
 'soado',
 'soais',
 'soamo',
 'soara',
 'soava',
 'sobai',
 'sobal',
 'sobem',
 'sobes',
 'sobra',
 'sobre',
 'sobro',
 'socai',
 'socam',
 'socao',
 'socar',
 'socas',
 'socia',
 'socio',
 'soclo',
 'socos',
 'socou',
 'sodar',
 'sodio',
 'sodre',
 'soeis',
 'soemo',
 'sofas',
 'sofia',
 'sofra',
 'sofre',
 'sofri',
 'sofro',
 'sogar',
 'sogra',
 'sogro',
 'sojas',
 'solai',
 'solam',
 'solao',
 'solar',
 'solas',
 'solaz',
 'solda',
 'solde',
 'soldo',
 'solei',
 'solem',
 'soleo',
 'soles',
 'solho',
 'solio',
 'solos',
 'solou',
 'solta',
 'solte',
 'solto',
 'somai',
 'somam',
 'somar',
 'somas',
 'somei',
 'somem',
 'somer',
 'somes',
 'somos',
 'somou',
 'sonar',
 'sonda',
 'sonde',
 'sondo',
 'songa',
 'songo',
 'sonha',
 'sonhe',
 'sonho',
 'sonia',
 'sonos',
 'sonsa',
 'sonso',
 'sopao',
 'sopas',
 'sopes',
 'soplo',
 'sopor',
 'sopra',
 'sopre',
 'sopro',
 'soque',
 'sorar',
 'sorda',
 'sorgo',
 'soria',
 'sorna',
 'soror',
 'soros',
 'sorri',
 'sorta',
 'sorte',
 'sorti',
 'sorva',
 'sorve',
 'sorvi',
 'sorvo',
 'sosia',
 'sotai',
 'sotao',
 'sotia',
 'soube',
 'soure',
 'sousa',
 'souza',
 'sovai',
 'sovam',
 'sovar',
 'sovas',
 'sovei',
 'sovem',
 'soves',
 'soveu',
 'sovou',
 'spins',
 'spray',
 'stato',
 'stela',
 'stilb',
 'stoma',
 'stout',
 'suada',
 'suado',
 'suais',
 'suamo',
 'suara',
 'suava',
 'suave',
 'suazi',
 'subam',
 'subas',
 'suber',
 'subia',
 'subir',
 'subis',
 'subiu',
 'sucao',
 'sucio',
 'sucos',
 'sucre',
 'sudao',
 'sudar',
 'sueca',
 'sueco',
 'suede',
 'sueis',
 'sueli',
 'suemo',
 'sueto',
 'suevo',
 'sufle',
 'sugai',
 'sugam',
 'sugar',
 'sugas',
 'sugou',
 'sugue',
 'suica',
 'suico',
 'suina',
 'suino',
 'suite',
 'sujai',
 'sujam',
 'sujao',
 'sujar',
 'sujas',
 'sujei',
 'sujem',
 'sujes',
 'sujos',
 'sujou',
 'sular',
 'sulca',
 'sulco',
 'sulfa',
 'sulfo',
 'sumam',
 'sumas',
 'sumbe',
 'sumia',
 'sumir',
 'sumis',
 'sumiu',
 'sumos',
 'sundo',
 'sunem',
 'sunga',
 'sunto',
 'super',
 'supim',
 'supoe',
 'supor',
 'supos',
 'supra',
 'supre',
 'supri',
 'supro',
 'supus',
 'sural',
 'surda',
 'surdo',
 'surge',
 'surgi',
 'surgo',
 'surja',
 'surjo',
 'surra',
 'surre',
 'surro',
 'surta',
 'surte',
 'surto',
 'surui',
 'susan',
 'sushi',
 'sussu',
 'susta',
 'suste',
 'susti',
 'susto',
 'sutia',
 'sutil',
 'sutis',
 'sutra',
 'taate',
 'tabai',
 'tabas',
 'tabla',
 'taboa',
 'tabor',
 'tabua',
 'tacai',
 'tacam',
 'tacao',
 'tacar',
 'tacas',
 'tacha',
 'tache',
 'tacho',
 'tacio',
 'tacos',
 'tacou',
 'tacto',
 'tacua',
 'tadeo',
 'tadeu',
 'tafia',
 'taful',
 'tagal',
 'tagma',
 'tagua',
 'tahir',
 'taias',
 'taico',
 'taifa',
 'taiga',
 'taipa',
 'taipe',
 'taipu',
 'taisa',
 'taita',
 'taiti',
 'tajas',
 'talao',
 'talar',
 'talas',
 'talco',
 'taler',
 'talha',
 'talhe',
 'talho',
 'talia',
 'talim',
 'talin',
 'talio',
 'talor',
 'talos',
 'talus',
 'tamam',
 'tamar',
 'tamba',
 'tambi',
 'tambo',
 'tambu',
 'tamia',
 'tamil',
 'tamim',
 'tamir',
 'tamis',
 'tampa',
 'tampe',
 'tampo',
 'tamul',
 'tamuz',
 'tanal',
 'tando',
 'tanga',
 'tange',
 'tangi',
 'tango',
 'tanho',
 'tanio',
 'tanis',
 'tanja',
 'tanjo',
 'tanta',
 'tanto',
 'tanus',
 'tapai',
 'tapam',
 'tapao',
 'tapar',
 'tapas',
 'tapea',
 'tapei',
 'tapem',
 'tapes',
 'tapia',
 'tapir',
 'tapiz',
 'tapou',
 'tapua',
 'taque',
 'taqui',
 'tarao',
 'tarar',
 'taras',
 'tarca',
 'tarda',
 'tarde',
 'tardo',
 'tarea',
 'taria',
 'tarja',
 'tarol',
 'tarro',
 'tarso',
 'tarta',
 'tarte',
 'tasso',
 'tasto',
 'tatau',
 'tatea',
 'tatil',
 'tatra',
 'tatua',
 'tatue',
 'tatui',
 'tatuo',
 'tatus',
 'tauro',
 'tavao',
 'taxai',
 'taxam',
 'taxar',
 'taxas',
 'taxei',
 'taxem',
 'taxes',
 'taxis',
 'taxon',
 'taxou',
 'tchad',
 'tchau',
 'teada',
 'teano',
 'tearo',
 'tease',
 'tebas',
 'tebes',
 'tecal',
 'tecam',
 'tecas',
 'tecei',
 'tecem',
 'tecer',
 'teces',
 'teceu',
 'tecia',
 'tecla',
 'tecle',
 'teclo',
 'tecno',
 'tecoa',
 'tecto',
 'tecum',
 'tedio',
 'teera',
 'teias',
 'teima',
 'teime',
 'teimo',
 'teina',
 'teira',
 'teiru',
 'teius',
 'teixe',
 'teixo',
 'telao',
 'telar',
 'telas',
 'telem',
 'teles',
 'telex',
 'telha',
 'telim',
 'telio',
 'teliz',
 'telmo',
 'telso',
 'temam',
 'temao',
 'temas',
 'tembe',
 'tembi',
 'temei',
 'temem',
 'temer',
 'temes',
 'temeu',
 'temia',
 'temis',
 'temor',
 'temos',
 'tempe',
 'tempo',
 'tenar',
 'tenaz',
 'tenca',
 'tenda',
 'tende',
 'tendi',
 'tendo',
 'tengo',
 'tenha',
 'tenho',
 'tenia',
 'tenis',
 'tenor',
 'tenra',
 'tenro',
 'tensa',
 'tense',
 'tenso',
 'tenta',
 'tente',
 'tento',
 'tenue',
 'teoro',
 'teose',
 'terai',
 'terao',
 'teras',
 'terca',
 'terco',
 'terei',
 'terem',
 'teres',
 'tergo',
 'teria',
 'terio',
 'terma',
 'termo',
 'terna',
 'terno',
 'terol',
 'terra',
 'terre',
 'terso',
 'tesao',
 'tesas',
 'teses',
 'tesis',
 'tesla',
 'tesos',
 'tesse',
 'testa',
 'teste',
 'testo',
 'tetar',
 'tetas',
 'teteu',
 'tetos',
 'tetra',
 'tetro',
 'tetum',
 'teudo',
 'teuto',
 'texas',
 'texto',
 'tiago',
 'tiano',
 'tiara',
 'tibar',
 'tibau',
 'tiber',
 'tibia',
 'tibio',
 'tibni',
 'tibre',
 'tical',
 'ticao',
 'ticar',
 'ticio',
 'ticos',
 'ticum',
 'tidal',
 'tidas',
 'tidor',
 'tidos',
 'tiete',
 'tifao',
 'tifsa',
 'tigre',
 'tilar',
 'tilho',
 'tilia',
 'tilim',
 'tilom',
 'tilos',
 'timao',
 'timar',
 'timba',
 'timbo',
 'timbu',
 'times',
 'timeu',
 'timia',
 'timna',
 'timol',
 'timon',
 'timor',
 'tinam',
 'tinas',
 'tinca',
 'tinea',
 'tinem',
 'tiner',
 'tines',
 'tinga',
 'tinge',
 'tingi',
 'tinha',
 'tinia',
 'tinir',
 'tinis',
 'tiniu',
 'tinja',
 'tinjo',
 'tinta',
 'tinto',
 'tione',
 'tiple',
 'tipos',
 'tique',
 'tirai',
 'tiram',
 'tirao',
 'tirar',
 'tiras',
 'tirei',
 'tirem',
 'tireo',
 'tires',
 'tiria',
 'tirio',
 'tirol',
 'tiros',
 'tirou',
 'tirso',
 'tirza',
 'tisso',
 'tissu',
 'titas',
 'titia',
 'titio',
 'tiuba',
 'tiver',
 'tizio',
 'tiziu',
 'tmese',
 'toada',
 'tocai',
 'tocam',
 'tocar',
 'tocas',
 'tocha',
 'tocos',
 'tocou',
 'todas',
 'todos',
 'tofel',
 'togar',
 'togas',
 'toira',
 'toiro',
 'toita',
 'tojos',
 'tolar',
 'tolas',
 'toldo',
 'toler',
 'tolha',
 'tolhe',
 'tolhi',
 'tolho',
 'tolos',
 'tomai',
 'tomam',
 'tomao',
 'tomar',
 'tomas',
 'tomba',
 'tombe',
 'tombo',
 'tomei',
 'tomem',
 'tomes',
 'tomia',
 'tomio',
 'tomos',
 'tomou',
 'tonal',
 'tonar',
 'tondo',
 'tonel',
 'toner',
 'tonga',
 'tongo',
 'tonho',
 'tonia',
 'tonta',
 'tonto',
 'tonus',
 'topai',
 'topam',
 'topar',
 'topas',
 'topaz',
 'topei',
 'topem',
 'topes',
 'topos',
 'topou',
 'toque',
 'toral',
 'torar',
 'toras',
 'torax',
 'torca',
 'torce',
 'torci',
 'torco',
 'torda',
 'tordo',
 'torem',
 'torga',
 'torio',
 'torna',
 'torne',
 'torno',
 'toros',
 'torpe',
 'torra',
 'torre',
 'torro',
 'torso',
 'torta',
 'torto',
 'tosai',
 'tosam',
 'tosao',
 'tosar',
 'tosas',
 'tosca',
 'tosco',
 'tosei',
 'tosem',
 'toses',
 'tosou',
 'tosse',
 'tossi',
 'tosta',
 'toste',
 'tosto',
 'total',
 'totem',
 'touca',
 'toura',
 'touro',
 'traca',
 'trace',
 'traco',
 'traem',
 'traga',
 'trago',
 'traia',
 'traio',
 'trair',
 'trais',
 'traiu',
 'traja',
 'traje',
 'trajo',
 'trama',
 'trans',
 'trapo',
 'trara',
 'trata',
 'trate',
 'trato',
 'trava',
 'trave',
 'travo',
 'traze',
 'treco',
 'tredo',
 'trema',
 'treme',
 'tremi',
 'tremo',
 'trena',
 'treno',
 'trens',
 'trepa',
 'trepe',
 'trepo',
 'treta',
 'treva',
 'trevo',
 'treze',
 'trial',
 'triao',
 'triar',
 'tribo',
 'trico',
 'tridi',
 'triga',
 'trigo',
 'trina',
 'trino',
 'triol',
 'trios',
 'tripa',
 'tripe',
 'trips',
 'troar',
 'troca',
 'troce',
 'troco',
 'trofo',
 'troge',
 'troia',
 'trole',
 'trono',
 'tropa',
 'trota',
 'trote',
 'troto',
 'trova',
 'truao',
 'truco',
 'trude',
 'trufa',
 'truta',
 'tubal',
 'tuber',
 'tubos',
 'tucho',
 'tucum',
 'tudel',
 'tudos',
 'tufao',
 'tufos',
 'tugir',
 'tuias',
 'tuins',
 'tuira',
 'tuiue',
 'tulha',
 'tulio',
 'tumba',
 'tumim',
 'tumor',
 'tunal',
 'tunar',
 'tunel',
 'tunes',
 'tupim',
 'tupis',
 'tuplo',
 'tuque',
 'turar',
 'turba',
 'turbe',
 'turbo',
 'turca',
 'turco',
 'turfa',
 'turfe',
 'turim',
 'turma',
 'turne',
 'turno',
 'turva',
 'turve',
 'turvo',
 'tussa',
 'tusso',
 'tutor',
 'tutsi',
 'tutti',
 'tutum',
 'uaipi',
 'uanga',
 'uapes',
 'uaura',
 'ubaia',
 'ubere',
 'uchoa',
 'udana',
 'ufana',
 'ufane',
 'ufano',
 'uiara',
 'uibai',
 'uigur',
 'uivai',
 'uivam',
 'uivar',
 'uivas',
 'uivei',
 'uivem',
 'uives',
 'uivos',
 'uivou',
 'ulema',
 'ulhoa',
 'ulnal',
 'ulnar',
 'ulopa',
 'ulose',
 'ultor',
 'ultra',
 'ulula',
 'ulule',
 'ululo',
 'umari',
 'umbro',
 'umero',
 'umida',
 'umido',
 'umiri',
 'unais',
 'unani',
 'uncao',
 'uncia',
 'uncus',
 'undos',
 'unedo',
 'unemo',
 'unesa',
 'ungem',
 'unges',
 'ungia',
 'ungir',
 'ungis',
 'ungiu',
 'unhao',
 'unhas',
 'uniam',
 'uniao',
 'unias',
 'unica',
 'unico',
 'unida',
 'unido',
 'unila',
 'unimo',
 'unira',
 'unita',
 'untai',
 'untam',
 'untar',
 'untas',
 'untei',
 'untem',
 'untes',
 'untor',
 'untou',
 'uraco',
 'urais',
 'uralo',
 'urano',
 'urban',
 'urdam',
 'urdas',
 'urdem',
 'urdes',
 'urdia',
 'urdir',
 'urdis',
 'urdiu',
 'ureia',
 'urgia',
 'urgir',
 'urgis',
 'urgiu',
 'urias',
 'urico',
 'uriel',
 'urien',
 'urina',
 'urine',
 'urino',
 'urjam',
 'urjas',
 'urnas',
 'urrai',
 'urram',
 'urrar',
 'urras',
 'urrei',
 'urrem',
 'urres',
 'urros',
 'urrou',
 'ursas',
 'ursel',
 'ursos',
 'urubu',
 'urucu',
 'urupa',
 'urupe',
 'urutu',
 'urzes',
 'usada',
 'usado',
 'usais',
 'usamo',
 'usara',
 'usava',
 'useis',
 'usemo',
 'usina',
 'usnea',
 'ussia',
 'ustos',
 'usual',
 'usura',
 'utata',
 'uteis',
 'utero',
 'uvaia',
 'uveal',
 'uvite',
 'uvula',
 'uzias',
 'uziel',
 'va-se',
 'vacao',
 'vacar',
 'vacas',
 'vacum',
 'vacuo',
 'vadea',
 'vades',
 'vadia',
 'vadie',
 'vadio',
 'vaduz',
 'vagai',
 'vagal',
 'vagam',
 'vagao',
 'vagar',
 'vagas',
 'vagem',
 'vagia',
 'vagil',
 'vagir',
 'vagis',
 'vagiu',
 'vagos',
 'vagou',
 'vague',
 'vaiai',
 'vaiam',
 'vaiar',
 'vaias',
 'vaiei',
 'vaiem',
 'vaies',
 'vaiou',
 'valao',
 'valar',
 'valas',
 'valdo',
 'valei',
 'valem',
 'valer',
 'vales',
 'valeu',
 'valga',
 'valgo',
 'valha',
 'valho',
 'valia',
 'valim',
 'valor',
 'valos',
 'valsa',
 'valva',
 'valvu',
 'vamos',
 'vampe',
 'vania',
 'vante',
 'vapor',
 'varai',
 'varal',
 'varam',
 'varao',
 'varar',
 'varas',
 'varei',
 'varem',
 'vares',
 'varia',
 'varie',
 'varig',
 'vario',
 'variz',
 'varoa',
 'varou',
 'varra',
 'varre',
 'varri',
 'varro',
 'varve',
 'vasal',
 'vasco',
 'vasos',
 'vasta',
 'vasti',
 'vasto',
 'vatua',
 'vazai',
 'vazam',
 'vazao',
 'vazar',
 'vazas',
 'vazei',
 'vazem',
 'vazes',
 'vazia',
 'vazio',
 'vazou',
 'veado',
 'vedai',
 'vedam',
 'vedar',
 'vedas',
 'vedei',
 'vedem',
 'vedes',
 'vedor',
 'vedou',
 'vegas',
 'veias',
 'veiga',
 'veios',
 'veiro',
 'vejai',
 'vejam',
 'vejas',
 'velai',
 'velam',
 'velar',
 'velas',
 'velei',
 'velem',
 'veles',
 'velha',
 'velho',
 'velos',
 'velou',
 'veloz',
 'vemos',
 'venal',
 'venca',
 'vence',
 'venci',
 'venco',
 'venda',
 'vende',
 'vendi',
 'vendo',
 'venha',
 'venho',
 'venia',
 'venta',
 'vente',
 'vento',
 'venus',
 'verao',
 'veras',
 'veraz',
 'verba',
 'verbi',
 'verbo',
 'verca',
 'verde',
 'verdi',
 'verei',
 'verem',
 'veres',
 'verga',
 'verge',
 'veria',
 'verio',
 'verme',
 'versa',
 'verse',
 'verso',
 'verta',
 'verte',
 'verti',
 'verto',
 'verve',
 'vesco',
 'vesga',
 'vesgo',
 'vespa',
 'veste',
 'vesti',
 'vetai',
 'vetam',
 'vetar',
 'vetas',
 'vetei',
 'vetem',
 'vetes',
 'vetor',
 'vetos',
 'vetou',
 'vexai',
 'vexam',
 'vexar',
 'vexas',
 'vexei',
 'vexem',
 'vexes',
 'vexou',
 'vezes',
 'viado',
 'viaja',
 'viaje',
 'viajo',
 'viana',
 'vibra',
 'vibre',
 'vibro',
 'vicar',
 'vichi',
 'vicia',
 'vicie',
 'vicio',
 'victo',
 'vidal',
 'vidar',
 'vidas',
 'video',
 'vides',
 'vidia',
 'vidra',
 'vidre',
 'vidro',
 'vieis',
 'viela',
 'viena',
 'viera',
 'vieza',
 'vigar',
 'vigas',
 'viger',
 'vigia',
 'vigie',
 'vigil',
 'vigio',
 'vigna',
 'vigor',
 'vilao',
 'vilas',
 'vilma',
 'viloa',
 'vimos',
 'vinca',
 'vinci',
 'vinco',
 'vinda',
 'vinde',
 'vindo',
 'vinga',
 'vingo',
 'vinha',
 'vinho',
 'vinil',
 'vinte',
 'vioes',
 'viola',
 'viole',
 'violo',
 'virai',
 'viral',
 'viram',
 'virao',
 'virar',
 'viras',
 'virei',
 'virem',
 'vireo',
 'vires',
 'virga',
 'virgo',
 'viria',
 'viril',
 'viris',
 'virmo',
 'virol',
 'viros',
 'virou',
 'virte',
 'virus',
 'visai',
 'visam',
 'visao',
 'visar',
 'visas',
 'visco',
 'visei',
 'visem',
 'vises',
 'viseu',
 'visgo',
 'visom',
 'visor',
 'visos',
 'visou',
 'visse',
 'vista',
 'viste',
 'visto',
 'vitae',
 'vital',
 'vitor',
 'vitre',
 'vitro',
 'viuva',
 'viuve',
 'viuvo',
 'vivam',
 'vivar',
 'vivas',
 'vivaz',
 'vivei',
 'vivem',
 'viver',
 'vives',
 'viveu',
 'vivia',
 'vivos',
 'vivre',
 'vizir',
 'voado',
 'voais',
 'voamo',
 'voara',
 'voava',
 'vocal',
 'voces',
 'vodca',
 'vodum',
 'voeis',
 'voemo',
 'vofsi',
 'vogal',
 'vogar',
 'vogas',
 'voile',
 'volar',
 'volei',
 'volta',
 'volte',
 'volto',
 'volts',
 'volva',
 'volve',
 'volvi',
 'volvo',
 'vomer',
 'voraz',
 'vossa',
 'vosso',
 'votai',
 'votam',
 'votar',
 'votas',
 'votei',
 'votem',
 'votes',
 'votos',
 'votou',
 'vouga',
 'vovos',
 'vozes',
 'vriti',
 'vulco',
 'vulgo',
 'vulto',
 'vulva',
 'vumbe',
 'vumbi',
 'watts',
 'weber',
 'xacra',
 'xadai',
 'xador',
 'xales',
 'xamba',
 'xampu',
 'xandu',
 'xango',
 'xaras',
 'xareu',
 'xaxim',
 'xciii',
 'xelim',
 'xenio',
 'xenon',
 'xenos',
 'xeque',
 'xerem',
 'xerez',
 'xerga',
 'xerox',
 'xexeu',
 'xibiu',
 'xiita',
 'xilol',
 'xinga',
 'xingo',
 'xingu',
 'xique',
 'xisto',
 'xogum',
 'xuate',
 'xucra',
 'xucro',
 'yocto',
 'yotta',
 'zaana',
 'zaava',
 'zabai',
 'zabdi',
 'zacai',
 'zacur',
 'zafom',
 'zagas',
 'zague',
 'zaide',
 'zaino',
 'zaira',
 'zaire',
 'zaldo',
 'zambe',
 'zambi',
 'zambo',
 'zande',
 'zanga',
 'zango',
 'zania',
 'zanoa',
 'zante',
 'zanza',
 'zanze',
 'zanzo',
 'zarao',
 'zarpa',
 'zarpe',
 'zarpo',
 'zarro',
 'zebra',
 'zebul',
 'zebus',
 'zeebe',
 'zefom',
 'zelai',
 'zelam',
 'zelar',
 'zelas',
 'zelda',
 'zelei',
 'zelem',
 'zeles',
 'zelio',
 'zelos',
 'zelou',
 'zelza',
 'zenas',
 'zende',
 'zenia',
 'zepto',
 'zerai',
 'zeram',
 'zerar',
 'zeras',
 'zerei',
 'zerem',
 'zeres',
 'zeror',
 'zeros',
 'zerou',
 'zetar',
 'zetta',
 'zezao',
 'zibia',
 'zicri',
 'zidim',
 'zigue',
 'zilda',
 'zilma',
 'zilpa',
 'zimbo',
 'zimra',
 'zimri',
 'zinco',
 'zinga',
 'zinho',
 'zinia',
 'zinir',
 'zinra',
 'zinri',
 'zipar',
 'ziper',
 'zipor',
 'zoete',
 'zofai',
 'zofar',
 'zoilo',
 'zomba',
 'zombe',
 'zombo',
 'zonal',
 'zonar',
 'zonas',
 'zonza',
 'zonzo',
 'zorra',
 'zorro',
 'zuavo',
 'zumba',
 'zumbe',
 'zumbi',
 'zumbo',
 'zunam',
 'zunas',
 'zunem',
 'zunes',
 'zunia',
 'zunir',
 'zunis',
 'zuniu',
 'zurra',
 'zurre',
 'zurro',
]
