export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Glórias a Deus!', 'Obrigado meu Deus!', 'Concluído com louvor!', 'Aleluia!', 'Hoje é só vitória', 'Maravilha', 'Tremendo!']
export const GAME_COPIED_MESSAGE = 'Game copiado para a clipboard'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Letras insuficientes'
export const WORD_NOT_FOUND_MESSAGE = 'Palavra não encontrada'
export const HARD_MODE_ALERT_MESSAGE =
  'O modo difícil só pode ser habilitado no início da partida!'
export const HARD_MODE_DESCRIPTION =
  'Todas as letras acertadas devem ser usadas no palpite seguinte'
export const DARK_MODE_DESCRIPTION = 'Modo escuro'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Modo alto contraste'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `A palavra é ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Guess must contain ${letter}`
export const ENTER_TEXT = 'ENTER'
export const STATISTICS_TITLE = 'Estatísticas'
export const GUESS_DISTRIBUTION_TEXT = 'Distribuição de palpites'
export const NEW_WORD_TEXT = 'Nova palavra em'
export const SHARE_TEXT = 'Compartilhar'
export const SHARE_FAILURE_TEXT =
  'Unable to share the results. This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.'
export const MIGRATE_BUTTON_TEXT = 'Transferir'
export const MIGRATE_DESCRIPTION_TEXT =
  'Clique aqui para transferir suas estatísticas para o novo dispositivo.'
export const TOTAL_TRIES_TEXT = 'Partidas'
export const SUCCESS_RATE_TEXT = 'Successo'
export const CURRENT_STREAK_TEXT = 'Sequência atual'
export const BEST_STREAK_TEXT = 'Recorde de sequência'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser."

export const DATEPICKER_TITLE = 'Escolha uma data'
export const DATEPICKER_CHOOSE_TEXT = 'Escolha'
export const DATEPICKER_TODAY_TEXT = 'hoje'
export const ARCHIVE_GAMEDATE_TEXT = 'Data do jogo'
